import { APIPartner } from "../../server/types/index.js";
import { AllActions } from "../actions/index.js";
import { Partner, PartnersState } from "../types/index.js";

const initialState: PartnersState = {};

const convertPartner = (partner: APIPartner): Partner => {
  const newPartner: Partner = {
    ...partner,
    logos: partner.logos.map((logo) => logo.id),
  };

  return newPartner;
};

const getPartnersSuccess = (partners: APIPartner[]): PartnersState => {
  return partners.reduce<PartnersState>((newState, partner) => {
    return {
      ...newState,
      [partner.id]: convertPartner(partner),
    };
  }, {});
};

const patchPartnerSuccess = (
  state: PartnersState,
  partner: APIPartner,
): PartnersState => {
  return { ...state, [partner.id]: convertPartner(partner) };
};

const reducer = (
  state: PartnersState = initialState,
  action: AllActions,
): PartnersState => {
  switch (action.type) {
    case "GET_PARTNERS_SUCCESS":
      return getPartnersSuccess(action.partners);

    case "PATCH_PARTNER_SUCCESS":
      return patchPartnerSuccess(state, action.partner);

    default:
      return state;
  }
};

export default reducer;
