import ClassNames from "classnames";
import { ChangeEventHandler, FunctionComponent, ReactNode } from "react";
import { style } from "typestyle";
import { FieldSetting } from "../types/index.js";

interface FormFieldProps {
  id: string;
  name: string;
  required: boolean;
  value: string;
  className: string;
  placeholder: string;
  onChange: ChangeEventHandler<
    HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
  >;
}

interface Props {
  formId: string;
  label: string;
  name: string;
  render: (props: FormFieldProps) => ReactNode;
  value: string | null;
  onChange: (value: string) => void;
  className?: string;
  settings: FieldSetting;
  colors:
    | {
        background: string;
        text: string;
      }
    | undefined;
}

const FormField: FunctionComponent<Props> = ({
  formId,
  name,
  label,
  render,
  value,
  onChange,
  className,
  settings,
  colors,
}) => {
  const fieldId = `${formId}-${name}`;
  const required = settings === "required";

  return settings === "disabled" ? null : (
    <div className="NewsletterModule__Field">
      <label
        className={ClassNames("NewsletterModule__FieldLabel", {
          "NewsletterModule__FieldLabel--required": required,
        })}
        htmlFor={fieldId}
      >
        {label}
      </label>
      {render({
        id: fieldId,
        name,
        required,
        value: value ?? "",
        placeholder: label + (required ? " *" : ""),
        className: ClassNames(
          "NewsletterModule__FieldInput",
          className,
          colors
            ? style({
                backgroundColor: colors.background,
                color: colors.text,
              })
            : undefined,
          { "NewsletterModule__FieldInput--with-border": !colors },
        ),
        onChange: (e) => onChange(e.target.value),
      })}
    </div>
  );
};

export default FormField;
