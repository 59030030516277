import ClassNames from "classnames";
import {
  FunctionComponent,
  MutableRefObject,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { ConnectedProps, MapStateToProps, connect } from "react-redux";
import { injectScript } from "../../actions/LoadStates.js";
import { getActiveSite } from "../../selectors/sites.js";
import {
  Accommodation,
  BaseModuleProps,
  LoadStatus,
  MapModuleSettings,
  Process,
  StoreState,
  WindowState,
} from "../../types/index.js";
import LazyloadWrapper from "../LazyloadWrapper.js";
import UsercentricsCMPWrapper from "../UsercentricsCMPWrapper.js";

declare const process: Process;

const getAPIURL = (apiKey: string | null): URL => {
  const url = new URL("https://maps.googleapis.com/maps/api/js");
  url.searchParams.set("key", apiKey ?? process.env.GOOGLE_MAPS_API_KEY);
  return url;
};

const mapOptions: google.maps.MapOptions = {
  scrollwheel: false,
  styles: [
    {
      featureType: "poi",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "transit.station",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
  ],
};

const initMap = ({
  accommodation,
  elRef,
  zoom,
  mapRef,
  markerRef,
  infoWindowRef,
  infoWindowListenerRef,
}: {
  accommodation: Accommodation;
  elRef: RefObject<HTMLDivElement>;
  zoom: number;
  mapRef: MutableRefObject<google.maps.Map | undefined>;
  markerRef: MutableRefObject<google.maps.Marker | undefined>;
  infoWindowRef: MutableRefObject<google.maps.InfoWindow | undefined>;
  infoWindowListenerRef: MutableRefObject<
    google.maps.MapsEventListener | undefined
  >;
}) => {
  if (!elRef.current) return;

  const position = {
    lat: accommodation.latitude,
    lng: accommodation.longitude,
  };

  mapRef.current = new google.maps.Map(elRef.current, {
    ...mapOptions,
    zoom,
    center: position,
  });
  markerRef.current = new google.maps.Marker({
    position,
    map: mapRef.current,
    title: accommodation.name,
  });
  infoWindowRef.current = new google.maps.InfoWindow({
    content: accommodation.name,
  });
  infoWindowListenerRef.current = google.maps.event.addListener(
    markerRef.current,
    "click",
    () => {
      infoWindowRef.current?.open(mapRef.current, markerRef.current);
    },
  );
  infoWindowRef.current.open(mapRef.current, markerRef.current);
};

interface Props extends BaseModuleProps<MapModuleSettings> {}

interface StateProps {
  accommodation: Accommodation | undefined;
  loadStatus: LoadStatus;
  apiKey: string | null;
}

type ReduxProps = ConnectedProps<typeof connector>;

const MapModule: FunctionComponent<Props & ReduxProps> = ({
  translatedModule,
  isPreview,
  loadStatus,
  injectScript,
  accommodation,
  isActive,
  apiKey,
}) => {
  const [isMapInitialized, setIsMapInitialized] = useState(false);
  const [isLazyloaded, setIsLazyloaded] = useState(false);
  const [consentIsGiven, setConsentIsGiven] = useState(isPreview);
  const elRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<google.maps.Map>();
  const markerRef = useRef<google.maps.Marker>();
  const infoWindowRef = useRef<google.maps.InfoWindow>();
  const infoWindowListenerRef = useRef<google.maps.MapsEventListener>();

  const showTextOverlay = !isMapInitialized && isPreview;
  const apiURL = getAPIURL(apiKey);

  useEffect(() => {
    if (loadStatus === "loaded" && accommodation) {
      initMap({
        accommodation,
        elRef,
        infoWindowListenerRef,
        infoWindowRef,
        mapRef,
        markerRef,
        zoom: translatedModule.settings.zoom,
      });
      setIsMapInitialized(true);
    }
  }, [loadStatus, accommodation]);

  useEffect(() => {
    mapRef.current?.setZoom(translatedModule.settings.zoom);
  }, [translatedModule.settings.zoom]);

  // On unmount
  useEffect(() => {
    return () => {
      const { google } = window as unknown as WindowState;
      if (!google || !google.maps) return;

      // There is currently no way to remove a map instace without creating a memory leak
      // https://stackoverflow.com/questions/21142483/google-maps-js-v3-detached-dom-tree-memory-leak
      infoWindowListenerRef.current &&
        google.maps.event.removeListener(infoWindowListenerRef.current);
      markerRef.current?.setMap(null);
    };
  }, []);

  const loadAPI = () => {
    injectScript(apiURL.toString());
  };

  useEffect(() => {
    isPreview && isActive && loadAPI();
  }, [isPreview, isActive]);

  useEffect(() => {
    !isPreview && !isActive && consentIsGiven && isLazyloaded && loadAPI();
  }, [isPreview, isActive, isLazyloaded, consentIsGiven]);

  return (
    <div
      id={translatedModule.id}
      className={ClassNames("MapModule Module", {
        "MapModule--loaded": isMapInitialized,
      })}
    >
      <LazyloadWrapper onLoad={setIsLazyloaded}>
        <UsercentricsCMPWrapper
          languageId={translatedModule.translation.languageId}
          serviceName="Google Maps"
          onConsentGiven={() => setConsentIsGiven(true)}
          consentIsGiven={consentIsGiven}
        >
          <div className="MapModule__Container" ref={elRef} />
          {showTextOverlay && (
            <div className="MapModule__TextOverlay">
              Klicken Sie hier, um die Karte zu laden.
            </div>
          )}
        </UsercentricsCMPWrapper>
      </LazyloadWrapper>
    </div>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { accommodation, loadStates, sites },
  { translatedModule },
): StateProps => {
  const apiKey = getActiveSite(sites).googleMapsApiKey;
  const apiURL = getAPIURL(apiKey);

  return {
    apiKey: getActiveSite(sites).googleMapsApiKey,
    accommodation: accommodation[translatedModule.translation.languageId],
    loadStatus: loadStates.scripts[apiURL.toString()] ?? "unloaded",
  };
};

const mapDispatchToProps = {
  injectScript,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(MapModule);
