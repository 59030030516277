import ClassNames from "classnames";
import { useEffect } from "react";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import { style } from "typestyle";
import { getAccommodation } from "../../actions/Accommodation.js";
import {
  getTranslatedPartner,
  getTranslatedPartnerLogos,
} from "../../selectors/partnerLogos.js";
import { getPictureById } from "../../selectors/pictures.js";
import { getActiveSite, getPrimaryDomain } from "../../selectors/sites.js";
import {
  Accommodation,
  BaseModuleProps,
  ColorScheme,
  FooterLayoutVariant,
  FooterModuleSettings,
  HeaderModuleSettings,
  Language,
  LegalNavId,
  Module,
  PageState,
  Picture,
  StoreState,
  TranslatedPartnerLogo,
} from "../../types/index.js";
import {
  getActiveColorScheme,
  getActiveLogoId,
  getTranslatedPage,
  getTranslations,
  isLegalNavId,
} from "../../utils/utils.js";
import InlineTerms from "../InlineTerms.js";
import JsonLd from "../JsonLd.js";
import LegalNavLink from "../LegalNavLink.js";
import PartnerLogos from "../PartnerLogos.js";
import RichEditorWrapper from "../RichEditorWrapper.js";
import TrustYou from "../TrustYou.js";

type Props = BaseModuleProps<FooterModuleSettings>;

type I18n = typeof translations.en;

interface StateProps {
  accommodation: Accommodation | undefined;
  mainDescription: string | null;
  scheme: ColorScheme;
  legalNav: LegalNavId[];
  i18n: I18n;
  vatId?: string;
  domain: string;
  trustYouLogo: TranslatedPartnerLogo | undefined;
  partnerLogoCategories: TranslatedPartnerLogo[][];
  logo: Picture;
}

type ReduxProps = ConnectedProps<typeof connector>;

type AllProps = Props & ReduxProps;

const translations = {
  de: {
    vat: "MwSt.-Nr.",
    region: "Südtirol",
  },
  it: {
    vat: "Part. IVA",
    region: "Alto Adige",
  },
  en: {
    vat: "VAT-No.",
    region: "South Tyrol",
  },
  fr: {
    vat: "numéro de TVA",
    region: "Sud-Tyrol",
  },
};

const _isBig = (layoutVariant: FooterLayoutVariant) => layoutVariant === "big";

const FooterModule: React.FunctionComponent<AllProps> = ({
  accommodation,
  translatedModule,
  translatedModule: {
    siteId,
    translation: {
      languageId,
      settings: { title, description },
    },
    settings,
    settings: { layoutVariant },
  },
  scheme,
  legalNav,
  i18n,
  vatId,
  isPreview,
  queries,
  domain,
  mainDescription,
  trustYouLogo,
  partnerLogoCategories,
  logo,
  getAccommodation,
  pageId,
}) => {
  useEffect(() => {
    getAccommodation(siteId, languageId);
  }, [siteId, languageId]);

  const hotelPhoto =
    accommodation && accommodation.pictures[0]
      ? accommodation.pictures[0].url
      : null;

  const isBig = _isBig(layoutVariant);

  return (
    <footer
      className={ClassNames(
        `FooterModule FooterModule--${layoutVariant}`,
        "Module",
        style({
          background: scheme.main.background,
          color: scheme.main.text,
        }),
      )}
      id={translatedModule.id}
    >
      <div className="Module__Wrapper">
        {isBig && (
          <div
            className={ClassNames(
              "FooterModule__Top",
              style({ borderColor: scheme.primary.background }),
            )}
          >
            <PartnerLogos
              partnerLogos={partnerLogoCategories[0]}
              logoSize={settings.logoSize}
            />
          </div>
        )}
        <div
          className={ClassNames(
            "FooterModule__Main",
            style({ borderColor: scheme.primary.background }),
          )}
        >
          <section className="FooterModule__Contact FooterModule__Section">
            {title && (
              <h4
                className={`FooterModule__Title TextAlign--${settings.textAlign.title}`}
              >
                {title}
              </h4>
            )}
            {accommodation && (
              <div className={`TextAlign--${settings.textAlign.description}`}>
                {!description && (
                  <div className="RichTextCopySource">
                    <div>{accommodation.name}</div>
                    <div>{accommodation.street}</div>
                    <div>
                      {accommodation.zip} {accommodation.city}
                    </div>
                    <div>Tel. {accommodation.phone}</div>
                    <div>
                      <a href={"mailto:" + accommodation.email}>
                        {accommodation.email}
                      </a>
                    </div>
                  </div>
                )}

                <RichEditorWrapper
                  pageId={pageId}
                  languageId={languageId}
                  module={translatedModule}
                  isPreview={isPreview}
                  scheme={undefined}
                  maxCharacters={250}
                  className="FooterModule__TextContent"
                />

                <JsonLd
                  data={{
                    "@type": "Hotel",
                    description: mainDescription,
                    name: accommodation.name,
                    address: {
                      "@type": "PostalAddress",
                      addressCountry: "IT",
                      addressLocality: accommodation.city,
                      addressRegion: i18n.region,
                      postalCode: accommodation.zip,
                      streetAddress: accommodation.street,
                    },
                    logo: logo.url,
                    url: `https://${domain}/`,
                    photo: hotelPhoto,
                    image: hotelPhoto,
                    aggregateRating: accommodation.trustYou
                      ? {
                          "@type": "AggregateRating",
                          ratingCount: accommodation.trustYou.count,
                          ratingValue: accommodation.trustYou.value,
                          bestRating: 5,
                        }
                      : null,
                    telephone: accommodation.phone,
                    email: accommodation.email,
                    faxNumber: accommodation.fax,
                    vatID: vatId,
                    starRating: {
                      "@type": "Rating",
                      ratingValue: accommodation.stars,
                    },
                    checkInTime: accommodation.checkIn.from,
                    checkOutTime: accommodation.checkOut.to,
                    petsAllowed:
                      (accommodation.featureMap & 32768) +
                        (accommodation.featureMap & 33554432) >
                      0,
                    geo: {
                      "@type": "GeoCoordinates",
                      latitude: accommodation.latitude,
                      longitude: accommodation.longitude,
                    },
                    priceRange: accommodation.priceRange,
                    currenciesAccepted: "EUR",
                    amenityFeature: accommodation.features.map((feature) => ({
                      "@type": "LocationFeatureSpecification",
                      name: feature.title,
                      value: true,
                    })),
                  }}
                />
              </div>
            )}
          </section>
          {!isBig && (
            <PartnerLogos
              partnerLogos={partnerLogoCategories[0]}
              logoSize={settings.logoSize}
              className="PartnerLogos--compact-last"
            />
          )}
          {isBig && (
            <PartnerLogos
              partnerLogos={partnerLogoCategories[1]}
              logoSize={settings.logoSize}
              className="PartnerLogos--compact-last"
            />
          )}
          {settings.showTrustYou && accommodation && accommodation.trustYou && (
            <div className="FooterModule__Widgets">
              <TrustYou
                {...accommodation.trustYou}
                logo={trustYouLogo}
                languageId={languageId}
              />
            </div>
          )}
        </div>
        <div className="FooterModule__Sub">
          {!isBig && (
            <PartnerLogos
              partnerLogos={partnerLogoCategories[1]}
              logoSize={settings.logoSize}
              className="PartnerLogos--compact-first"
            />
          )}
          <ul className="FooterModule__Nav Nav">
            {!!accommodation?.cin.length && (
              <li>CIN: {accommodation.cin.join(", ")}</li>
            )}
            {vatId && (
              <li>
                {i18n.vat} {vatId}
              </li>
            )}
            {legalNav.map((id) => (
              <li key={id}>
                <LegalNavLink
                  legalNavId={id}
                  languageId={languageId}
                  isPreview={isPreview}
                />
              </li>
            ))}

            <li className="FooterModule__Hgv">
              Website by{" "}
              <a href="https://www.hgv.it/" target="_blank" rel="noopener">
                HGV
              </a>
            </li>
          </ul>
        </div>
        <InlineTerms
          siteId={siteId}
          languageId={languageId}
          colorScheme={scheme}
          isPreview={isPreview}
          queries={queries}
          isActive={false}
          isFirstOnPage={false}
        />
      </div>
    </footer>
  );
};

const getMainPageDescription = (
  pages: PageState,
  languageId: Language,
): string | null => {
  try {
    const { description } = getTranslatedPage(
      pages,
      pages.byParentId["null"][0],
      languageId,
    ).translation;
    return description;
  } catch (error) {
    // This means the main page is not translated in
    // this language
    return null;
  }
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  state,
  {
    translatedModule: {
      colorSchemeId,
      translation: { languageId },
      settings,
    },
  },
): StateProps => {
  const {
    pages,
    mediaLibrary: { logoIds, pictures },
    modules,
  } = state;
  const site = getActiveSite(state.sites);
  const trustYou = getTranslatedPartner(state, {
    partnerId: "trust-you",
    includeLogos: true,
    languageId,
  });
  const i18n: I18n = getTranslations(languageId, translations);
  const legalNav: LegalNavId[] = pages.systemPages
    .map((pageId) => pages.byId[pageId].alias)
    .filter(isLegalNavId);
  const description = getMainPageDescription(pages, languageId);

  const headerModuleId = modules.bySiteModuleType.HeaderModule?.[0];
  const headerModule = (
    headerModuleId ? modules.byId[headerModuleId] : undefined
  ) as Module<HeaderModuleSettings> | undefined;
  const logoId = headerModule?.settings.logoId;

  return {
    logo: getPictureById(pictures, getActiveLogoId(logoIds, logoId), {
      height: 120,
    }),
    partnerLogoCategories: settings.logoCategories.map((logoIds) =>
      getTranslatedPartnerLogos(state, { logoIds, languageId }),
    ),
    accommodation: state.accommodation[languageId],
    mainDescription: description,
    vatId: site.vatId || undefined,
    domain: getPrimaryDomain(site),
    i18n,
    scheme: getActiveColorScheme(state.colorSchemes, site, { colorSchemeId }),
    legalNav,
    trustYouLogo: trustYou?.logos[0],
  };
};

const mapDispatchToProps = {
  getAccommodation,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(FooterModule);
