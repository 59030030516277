import { ModuleType } from "../../../server/types/index.js";
import { AllActions } from "../../actions/index.js";
import { Module, ModulesByParentId, Page } from "../../types/index.js";
import { moveArrayElement, removeFromArray } from "../../utils/utils.js";

export const initialState: ModulesByParentId = {};

const postModuleStart = (
  state: ModulesByParentId,
  moduleId: string,
  moduleType: ModuleType,
  parentId: string | null,
  pageId: string | null,
): ModulesByParentId => {
  return updateModules(
    state,
    parentId,
    pageId,
    moduleType,
    (currentModules) => {
      return [...currentModules, moduleId];
    },
  );
};

const updateModules = (
  state: ModulesByParentId,
  parentId: string | null,
  pageId: string | null,
  moduleType: ModuleType,
  callback: (subModuleIds: string[]) => string[],
): ModulesByParentId => {
  if (!parentId) return state;

  const byPage = state[String(pageId)] || {};
  const subModuleCategories = byPage[parentId] || {};
  const subModuleIds = subModuleCategories[moduleType] || [];
  const newSubmoduleIds = callback(subModuleIds);

  return {
    ...state,
    [String(pageId)]: {
      ...byPage,
      [parentId]: {
        ...subModuleCategories,
        [moduleType]: newSubmoduleIds,
      },
    },
  };
};

const deleteModuleTranslation = ({
  state,
  pageId,
  moduleId,
  moduleType,
  parentId,
  deleteAllTranslations,
}: {
  state: ModulesByParentId;
  pageId: string | null;
  moduleId: string;
  moduleType: ModuleType;
  parentId: string | null;
  deleteAllTranslations: boolean;
}): ModulesByParentId => {
  if (!deleteAllTranslations) return state;

  return updateModules(
    state,
    parentId,
    pageId,
    moduleType,
    (currentModules) => {
      return removeFromArray<string>(currentModules, moduleId);
    },
  );
};

const getModules = (
  state: ModulesByParentId,
  modules: Module[],
  pageId: string | null,
): ModulesByParentId => {
  const modulesByParentId = modules.reduce<ModulesByParentId>(
    (newState, { id, parentId, type }) => {
      return updateModules(
        newState,
        parentId,
        pageId,
        type,
        (currentModules) => [...currentModules, id],
      );
    },
    {},
  );

  return { ...state, ...modulesByParentId };
};

const getModulesFromPages = (state: ModulesByParentId, pages: Page[]) =>
  pages.reduce<ModulesByParentId>(
    (carryState, page) => getModules(carryState, page.modules, page.id),
    state,
  );

const dragSubmodule = ({
  state,
  pageId,
  moduleType,
  parentId,
  dragIndex,
  hoverIndex,
}: {
  state: ModulesByParentId;
  pageId: string | null;
  moduleType: ModuleType;
  parentId: string;
  dragIndex: number;
  hoverIndex: number;
}): ModulesByParentId =>
  updateModules(state, parentId, pageId, moduleType, (currentModules) =>
    moveArrayElement<string>(currentModules, dragIndex, hoverIndex),
  );

const reducer = (state = initialState, action: AllActions) => {
  switch (action.type) {
    case "POST_MODULE_START":
      return postModuleStart(
        state,
        action.moduleId,
        action.moduleType,
        action.parentId,
        action.pageId,
      );

    case "DELETE_MODULE_TRANSLATION_START":
      return deleteModuleTranslation({
        state,
        pageId: action.pageId,
        moduleId: action.moduleId,
        moduleType: action.moduleType,
        parentId: action.parentId,
        deleteAllTranslations: action.deleteAllTranslations,
      });

    case "GET_SITE_MODULES_SUCCESS":
      return getModules(state, action.modules, null);

    case "GET_MODULES_SUCCESS":
      return getModules(state, action.modules, action.pageId);

    case "GET_PAGES_SUCCESS":
      return getModulesFromPages(state, action.pages);

    case "DRAG_SUBMODULE":
      return dragSubmodule({
        state,
        pageId: action.pageId,
        moduleType: action.moduleType,
        parentId: action.parentId,
        dragIndex: action.dragIndex,
        hoverIndex: action.hoverIndex,
      });

    default:
      return state;
  }
};

export default reducer;
