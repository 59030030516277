import ClassNames from "classnames";
import { FunctionComponent, useEffect, useRef } from "react";
import { ConnectedProps, MapStateToProps, connect } from "react-redux";
import { style } from "typestyle";
import { getSiteModule } from "../../selectors/modules.js";
import { getActiveSite } from "../../selectors/sites.js";
import {
  AspectRatio,
  BaseModuleProps,
  ColorScheme,
  Font,
  ImageModuleSettings,
  QuoteModuleSettings,
  StoreState,
  ThreeSizes,
  TranslatedModule,
} from "../../types/index.js";
import {
  addAndLoadFonts,
  checkFontExists,
  deleteFonts,
  getFontFaces,
} from "../../utils/fonts.js";
import {
  getActiveColorScheme,
  getImageSubModule,
  round,
} from "../../utils/utils.js";
import ModuleHeadings from "../ModuleHeadings.js";
import ModuleWithHeadings from "../ModuleWithHeadings.js";
import MultilineText from "../MultilineText.js";
import ImageModule from "./ImageModule.js";
import QuoteIcon from "./QuoteIcon.js";

type Props = BaseModuleProps<QuoteModuleSettings>;

interface StateProps {
  image: TranslatedModule<ImageModuleSettings> | undefined;
  scheme: ColorScheme;
  font: Font | undefined;
}

type ReduxProps = ConnectedProps<typeof connector>;

const QuoteModule: FunctionComponent<Props & ReduxProps> = ({
  scheme,
  translatedModule: {
    id: moduleId,
    settings: { textAlign, layout, mediaAlign, fontSize },
    translation: {
      settings: { title, subtitle, quote, author },
    },
  },
  isFirstOnPage,
  image,
  isPreview,
  pageId,
  font,
  queries,
}) => {
  const loadedFontsRef = useRef<FontFace[]>([]);

  useEffect(() => {
    // Abort if font is already loaded or if the
    // fonts API response is not yet loaded.
    if (!font || checkFontExists(font.family)) {
      return;
    }
    const fontFaces = getFontFaces(font);
    addAndLoadFonts(fontFaces);
    loadedFontsRef.current = fontFaces;
  }, [font]);

  useEffect(() => {
    return () => {
      deleteFonts(loadedFontsRef.current);
      loadedFontsRef.current = [];
    };
  }, [loadedFontsRef]);

  const fixedAspectRatio: AspectRatio = 1.7778;

  return (
    <ModuleWithHeadings
      className={`QuoteModule QuoteModule--${layout}`}
      id={moduleId}
      colors={{
        background: scheme.main.background,
        color: scheme.main.text,
      }}
      title={title}
      subtitle={subtitle}
    >
      <div className="Module__Wrapper Module__Wrapper--default-padding">
        <ModuleHeadings
          scheme={scheme}
          isFirstOnPage={isFirstOnPage}
          textAlign={textAlign}
          title={title}
          subtitle={subtitle}
        />
        <div
          className={ClassNames("QuoteModule__WrapperInner", {
            [`QuoteModule__WrapperInner--media-${mediaAlign}`]: image,
            "QuoteModule__WrapperInner--text-image": image,
          })}
        >
          {image && (
            <div className="QuoteModule__Image">
              <ImageModule
                aspectRatio={
                  queries["Query--medium"] ? undefined : fixedAspectRatio
                }
                lazyLoad={true}
                translatedModule={image}
                width={600}
                sizes="(min-width: 640px) 40vw, (min-width: 1600px) 600px, 100vw"
                isPreview={isPreview}
                isSlider={false}
                pageId={pageId}
                scheme={scheme}
                showOverlay={false}
              />
            </div>
          )}
          {(author || quote) && (
            <div
              className={`QuoteModule__Text TextAlign--${textAlign.description}`}
            >
              {quote && (
                <div className="QuoteModule__Quote">
                  <QuoteIcon layout={layout} scheme={scheme} type="open" />
                  <span
                    className={ClassNames(
                      "QuoteModule__QuoteText",
                      style({
                        fontFamily: font?.family,
                        fontSize:
                          getFontSize(font?.scale ?? 1, fontSize) + "em",
                      }),
                    )}
                  >
                    <MultilineText text={quote} />
                  </span>
                  <QuoteIcon layout={layout} scheme={scheme} type="close" />
                </div>
              )}
              {author && <div className="QuoteModule__Author">{author}</div>}
            </div>
          )}
        </div>
      </div>
    </ModuleWithHeadings>
  );
};

const getFontSize = (fontScale: number, sizeSetting: ThreeSizes): number => {
  const sizesMap: { [key in ThreeSizes]: number } = {
    small: 0.8,
    medium: 1,
    big: 1.15,
  };

  return round(fontScale * sizesMap[sizeSetting], 2);
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { colorSchemes, modules, sites, fonts },
  {
    translatedModule,
    translatedModule: {
      id: moduleId,
      translation: { languageId },
    },
    pageId,
  },
): StateProps => {
  const fontFamily =
    getSiteModule<QuoteModuleSettings>({
      languageId,
      moduleType: "QuoteModule",
      modules,
    })?.settings.fontFamily ?? undefined;

  const site = getActiveSite(sites);

  const defaultFontFamily = site.titleFontFamily ?? site.fontFamily;

  return {
    image: getImageSubModule({ languageId, pageId, moduleId, modules }),
    scheme: getActiveColorScheme(colorSchemes, site, translatedModule),
    font: fontFamily
      ? fonts.byFamily[fontFamily]
      : fonts.byFamily[defaultFontFamily],
  };
};

const connector = connect(mapStateToProps);

export default connector(QuoteModule);
