import { FunctionComponent } from "react";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import { NestedCSSProperties } from "typestyle/lib/types";
import { getActiveSite } from "../../selectors/sites.js";
import {
  BaseModuleProps,
  ColorScheme,
  HogastGastropoolPayModuleSettings,
  Language,
  StoreState,
} from "../../types/index.js";
import {
  getActiveColorScheme,
  getSupportedLanguage,
} from "../../utils/utils.js";
import IframeWidget from "../IframeWidget.js";
import ModuleHeadings from "../ModuleHeadings.js";
import ModuleWithHeadings from "../ModuleWithHeadings.js";

type Props = BaseModuleProps<HogastGastropoolPayModuleSettings>;

interface StateProps {
  scheme: ColorScheme;
}

type ReduxProps = ConnectedProps<typeof connector>;

const HogastGastropoolPayModule: FunctionComponent<Props & ReduxProps> = ({
  scheme,
  translatedModule: {
    id,
    settings: { textAlign, key },
    translation: {
      languageId,
      settings: { title, subtitle },
    },
  },
  isFirstOnPage,
  isActive,
  isPreview,
}) => {
  const baseUrl = getBaseUrl(key);
  const scriptUrl = getScriptUrl({ languageId, baseUrl, key });

  return (
    <ModuleWithHeadings
      title={title}
      subtitle={subtitle}
      id={id}
      className="HogastGastropoolPayModule"
      colors={{
        background: scheme.main.separator,
        color: scheme.main.text,
      }}
    >
      <div className="Module__Wrapper">
        <ModuleHeadings
          scheme={scheme}
          isFirstOnPage={isFirstOnPage}
          textAlign={textAlign}
          title={title}
          subtitle={subtitle}
        />
      </div>

      <div className="Module__Wrapper">
        {!!key && (
          <IframeWidget
            isActive={isActive}
            isPreview={isPreview}
            scriptUrl={scriptUrl}
            style={getStyle(scheme)}
          >
            <div id="rxdeposit-container">
              <div id="hg-ti-form">
                <div className="block">
                  <img src={`${baseUrl}/app_themes/deposit/loader.gif`} />
                </div>
              </div>
            </div>
          </IframeWidget>
        )}

        {!key && (
          <div className="HogastGastropoolPayModule__Empty">
            hogast/gastropool Zahlknopf
          </div>
        )}
      </div>
    </ModuleWithHeadings>
  );
};

const getBaseUrl = (key: string) =>
  ({
    hogast: "https://secure.hogast.it",
    gastropool: "https://secure.gastropool.it",
  })[key.length === 128 ? "gastropool" : "hogast"];

const getScriptUrl = ({
  languageId,
  baseUrl,
  key,
}: {
  languageId: Language;
  baseUrl: string;
  key: string;
}) => {
  const lang = getSupportedLanguage(languageId, ["de", "it", "en"]);
  return `${baseUrl}/rxdeposit/widget/${lang}?key=${key}`;
};

const getStyle = (scheme: ColorScheme): NestedCSSProperties => ({
  $nest: {
    "#hg-wi-form .h-wi-button": {
      color: scheme.primary.text,
      background: scheme.primary.background,
      borderRadius: 0,
    },
    "#hg-wi-form .h-wi-button:hover": {
      background: scheme.primary.background,
    },
    "#hg-wi-form": {
      background: scheme.main.background,
      maxWidth: "1200px",
      margin: "0 auto",
      padding: "3em 6%",
    },
    "#hg-wi-form .h-wi-footer .h-wi-text": {
      color: scheme.main.text,
    },
    "#hg-wi-form .h-wi-required-text": {
      color: scheme.main.text,
    },
    "#hg-wi-form .h-wi-content .h-wi-row .h-wi-value input": {
      background: scheme.secondary.background,
      color: scheme.secondary.text,
      border: 0,
      padding: "0.65em 0.75em",
      fontSize: "1em",
      height: "auto",
    },
    ".h-wi-amounts": {
      background: scheme.secondary.background,
      color: scheme.secondary.text,
    },
    "#hg-wi-form .h-wi-content .h-wi-row": {
      background: "transparent",
      borderBottom: 0,
    },
    "#hg-wi-form .h-wi-content .h-wi-row.h-wi-bold-row": {
      background: "transparent",
      borderBottom: 0,
    },
    "#hg-wi-form .h-wi-content .h-wi-row:not(:first-child)": {
      marginTop: "1em",
    },
  },
});

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { colorSchemes, sites },
  { translatedModule },
): StateProps => ({
  scheme: getActiveColorScheme(
    colorSchemes,
    getActiveSite(sites),
    translatedModule,
  ),
});

const connector = connect(mapStateToProps);

export default connector(HogastGastropoolPayModule);
