import ClassNames from "classnames";
import { FunctionComponent, RefCallback, useState } from "react";
import { CommonHeaderLayoutProps, PageState } from "../types/index.js";
import { useKeyDown } from "../utils/hooks.js";
import { getImageModuleWithFixedAspectRatio, keys } from "../utils/utils.js";
import FullHeaderMenu from "./FullHeaderMenu.js";
import FullMenuButton from "./FullMenuButton.js";
import HeaderActionLinks from "./HeaderActionLinks.js";
import HeaderLogo from "./HeaderLogo.js";
import Header3Mobile from "./HeaderMobile/Header3Mobile.js";
import LanguageMenu from "./LanguageMenu.js";
import ImagesModule from "./Modules/ImagesModule.js";

interface Props {
  intersectionTriggerRef: RefCallback<HTMLElement>;
  common: CommonHeaderLayoutProps;
}

const HeaderLayout3: FunctionComponent<Props> = ({
  intersectionTriggerRef,
  common,
  common: {
    accommodation,
    actionLinks,
    activePagePath,
    fallbackLanguageId,
    imageOverlayGradient,
    imagesModule,
    isPreview,
    languageId,
    logo,
    logoSize,
    logoBackground,
    mainPageURL,
    menuItems,
    pageId,
    pages,
    queries,
    scheme,
  },
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useKeyDown("Escape", () => setIsOpen(false));

  const isMultiLevel = getIsMultiLevel(pages);

  return (
    <>
      {imagesModule?.pageId && (
        <div
          className={ClassNames(
            "HeaderModule__Inner",
            "HeaderModule__Inner--wide",
            "HeaderModule__Inner--full-overlay-nav",
            `HeaderModule__Inner--image-overlay-gradient-${imageOverlayGradient}`,
            {
              "HeaderModule__Inner--full-height": !isPreview,
            },
          )}
        >
          <ImagesModule
            className="FullScreenHeaderImages"
            translatedModule={getImageModuleWithFixedAspectRatio(imagesModule)}
            isPreview={isPreview}
            pageId={pageId}
            queries={queries}
            isActive={false}
            isInsideHeader={true}
            isFirstOnPage={false}
            parentScheme={scheme}
            activeModuleId={undefined}
            fullHeight={!isPreview}
          />

          {isOpen && (
            <FullHeaderMenu
              fallbackLanguageId={fallbackLanguageId}
              isPreview={isPreview}
              pages={pages}
              menuItems={menuItems}
              languageId={languageId}
              onClose={() => setIsOpen(false)}
              activePagePath={activePagePath}
              isMultiLevel={isMultiLevel}
              actionLinks={actionLinks}
            />
          )}

          <div className="FullHeaderOverlay">
            <div className="FullHeaderOverlay__Inner">
              <HeaderLogo
                isPreview={isPreview}
                logo={logo}
                mainPageURL={mainPageURL}
                scheme={scheme}
                logoBackground={logoBackground}
                maxSize={{ width: 250, height: 100 }}
                size={logoSize}
              />
              <div className="FullHeaderOverlay__Box FullHeaderOverlay__Box--absolute">
                <HeaderActionLinks
                  languageId={languageId}
                  accommodation={accommodation}
                  isPreview={isPreview}
                  actionLinks={actionLinks}
                />

                <LanguageMenu
                  isPreview={isPreview}
                  languageId={languageId}
                  scheme={undefined}
                  pageId={pageId}
                  variant="vertical"
                  switchToSelectLimit={
                    queries["Query--xxlarge"] ? undefined : 2
                  }
                />
              </div>
              <FullMenuButton
                innerRef={intersectionTriggerRef}
                isOpen={false}
                languageId={languageId}
                onClick={() => setIsOpen(!isOpen)}
              />
            </div>
          </div>
        </div>
      )}

      <Header3Mobile {...common} />
    </>
  );
};

const getIsMultiLevel = (pages: PageState): boolean => {
  const { null: topLevel, ...rest } = pages.byParentId;
  const parentPageIds = keys(rest).filter(
    (pageId) => (pages.byParentId[pageId] ?? []).length,
  );

  return parentPageIds.length > 1;
};

export default HeaderLayout3;
