import { Feature } from "@bookingsuedtirol/mss-nodejs/dist/types/response.js";
import ClassNames from "classnames";
import { FunctionComponent } from "react";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import { style } from "typestyle";
import { getActiveSite } from "../../selectors/sites.js";
import {
  AccommodationFeaturesModuleSettings,
  AccommodationState,
  BaseModuleProps,
  ColorScheme,
  Language,
  StoreState,
} from "../../types/index.js";
import { getActiveColorScheme } from "../../utils/utils.js";
import AccommodationFeatureIcon from "../AccommodationFeatureIcon.js";
import ModuleHeadings from "../ModuleHeadings.js";
import ModuleWithHeadings from "../ModuleWithHeadings.js";
import ReadMoreCollapse from "../ReadMoreCollapse.js";

type Props = BaseModuleProps<AccommodationFeaturesModuleSettings>;

interface StateProps {
  scheme: ColorScheme;
  pinnedFeatures: Feature[];
}

type ReduxProps = ConnectedProps<typeof connector>;

const AccommodationFeaturesModule: FunctionComponent<Props & ReduxProps> = ({
  isFirstOnPage,
  translatedModule: {
    settings: { textAlign, collapsedLinesCount, maxColumns },
    translation: {
      languageId,
      settings: { title, subtitle },
    },
    id: moduleId,
  },
  scheme,
  pinnedFeatures,
}) => {
  const list = (
    <ul
      className={ClassNames(
        "AccommodationFeaturesModule__List",
        `AccommodationFeaturesModule__List--columns-${maxColumns}`,
      )}
    >
      {pinnedFeatures.map(({ id, title }) => (
        <li className="AccommodationFeaturesModule__ListItem" key={id}>
          <AccommodationFeatureIcon
            featureId={id}
            className={ClassNames(
              "AccommodationFeaturesModule__Icon",
              style({ fill: scheme.primary.background }),
            )}
          />
          <span>{title}</span>
        </li>
      ))}
    </ul>
  );

  return (
    <ModuleWithHeadings
      title={title}
      subtitle={subtitle}
      id={moduleId}
      className="AccommodationFeaturesModule"
      colors={{
        background: scheme.main.background,
        color: scheme.main.text,
      }}
    >
      <div className="Module__Wrapper Module__Wrapper--default-padding">
        <ModuleHeadings
          scheme={scheme}
          isFirstOnPage={isFirstOnPage}
          textAlign={textAlign}
          title={title}
          subtitle={subtitle}
        />

        {collapsedLinesCount !== undefined ? (
          <ReadMoreCollapse
            backgroundColor={scheme.main.background}
            languageId={languageId}
            collapsedLinesCount={collapsedLinesCount}
            isEditing={false}
          >
            {list}
          </ReadMoreCollapse>
        ) : (
          list
        )}
      </div>
    </ModuleWithHeadings>
  );
};

// the accomodation features regarding
// “Overview of operating features/inclusive services”
const featureIdsToOmit = [
  11, 154, 155, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166,
];

export const getAccommodationFeatures = (
  accommodation: AccommodationState,
  languageId: Language,
) => {
  const features =
    accommodation[languageId]?.features.filter(
      ({ id }) => !featureIdsToOmit.includes(id),
    ) ?? [];

  return {
    ids: features.map(({ id }) => id),
    titles: features.reduce<{ [id: number]: string }>(
      (accumulator, { id, title }) => {
        accumulator[id] = title;
        return accumulator;
      },
      {},
    ),
  };
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { colorSchemes, sites, accommodation },
  {
    translatedModule,
    translatedModule: {
      settings: { pinnedItems },
      translation: { languageId },
    },
  },
): StateProps => {
  const { ids, titles } = getAccommodationFeatures(accommodation, languageId);
  const pinnedFeatures = (pinnedItems.length ? pinnedItems : ids)
    .map<Feature>((id) => ({ id, title: titles[id] }))
    .filter(Boolean);

  return {
    pinnedFeatures,
    scheme: getActiveColorScheme(
      colorSchemes,
      getActiveSite(sites),
      translatedModule,
    ),
  };
};

const connector = connect(mapStateToProps);

export default connector(AccommodationFeaturesModule);
