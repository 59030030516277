import { CSSProperties, FunctionComponent } from "react";
import { ConnectedProps, MapStateToProps, connect } from "react-redux";
import { getActiveSite } from "../../selectors/sites.js";
import {
  ArriveInSouthTyrolModuleSettings,
  BaseModuleProps,
  ColorScheme,
  StoreState,
} from "../../types/index.js";
import {
  getActiveColorScheme,
  getSupportedLanguage,
} from "../../utils/utils.js";
import IframeWidget from "../IframeWidget.js";
import ModuleHeadings from "../ModuleHeadings.js";
import ModuleWithHeadings from "../ModuleWithHeadings.js";

type Props = BaseModuleProps<ArriveInSouthTyrolModuleSettings>;

interface StateProps {
  scheme: ColorScheme;
}

type ReduxProps = ConnectedProps<typeof connector>;

const ArriveInSouthTyrolModule: FunctionComponent<Props & ReduxProps> = ({
  scheme,
  translatedModule: {
    id,
    settings: { textAlign },
    translation: {
      languageId,
      settings: { title, subtitle },
    },
  },
  isFirstOnPage,
  isActive,
  isPreview,
}) => (
  <ModuleWithHeadings
    title={title}
    subtitle={subtitle}
    id={id}
    className="ArriveInSouthTyrolModule"
    colors={{
      background: scheme.main.separator,
      color: scheme.main.text,
    }}
  >
    <div className="Module__Wrapper">
      <ModuleHeadings
        scheme={scheme}
        isFirstOnPage={isFirstOnPage}
        textAlign={textAlign}
        title={title}
        subtitle={subtitle}
      />
    </div>

    <div className="Module__Wrapper">
      <IframeWidget
        isActive={isActive}
        isPreview={isPreview}
        scriptUrl="https://cdn.webcomponents.opendatahub.bz.it/dist/e743026a-e53d-4898-b886-e0ac1d9a0c48/widget.js"
        onLoad={(iframe) => {
          const doc = iframe.contentDocument;
          doc && addStyleTag(doc);
        }}
      >
        <div style={getCSSCustomProperties(scheme)}>
          <odh-howtoarriveinsouthtyrol-widget
            data-color={scheme.main.title}
            data-lang={getSupportedLanguage(languageId, ["de", "it", "en"])}
          />
        </div>
      </IframeWidget>
    </div>
  </ModuleWithHeadings>
);

const getCSSCustomProperties = (scheme: ColorScheme): CSSProperties =>
  ({
    "--text": scheme.main.text,
    "--title": scheme.main.title,
    "--background": scheme.main.background,
  }) as CSSProperties;

const addStyleTag = (doc: Document) => {
  const el = document.createElement("style");
  el.id = "custom-widget-style";

  // !important is necessary to overwrite the widget styles
  // which are very lengthy and overly specific.
  // This also overrides the data-color prop. That’s necessary
  // because the widget otherwise doesn't reload by
  // itself on a data-color change.
  el.textContent = `
    .odh-howtoarriveinsouthtyrol-slide-inner-text {
      color: var(--text) !important;
    }

    .odh-howtoarriveinsouthtyrol-slide-inner {
      background: var(--background) !important;
    }

    .odh-howtoarriveinsouthtyrol-widget .a-arrow {
      fill: var(--title) !important;
    }
  `;

  doc.head.appendChild(el);
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { colorSchemes, sites },
  { translatedModule },
): StateProps => ({
  scheme: getActiveColorScheme(
    colorSchemes,
    getActiveSite(sites),
    translatedModule,
  ),
});

const connector = connect(mapStateToProps);

export default connector(ArriveInSouthTyrolModule);
