import ClassNames from "classnames";
import { FunctionComponent } from "react";
import { connect, MapStateToProps } from "react-redux";
import { style } from "typestyle";
import { getActiveSite } from "../../selectors/sites.js";
import {
  BaseModuleProps,
  ColorScheme,
  SeparatorModuleSettings,
  StoreState,
} from "../../types/index.js";
import { getActiveColorScheme } from "../../utils/utils.js";
import SeparatorLine from "../SeparatorLine.js";
import SeparatorModuleIcons from "../SeparatorModuleIcons.js";

type Props = BaseModuleProps<SeparatorModuleSettings>;

interface StateProps {
  scheme: ColorScheme;
}

const SeparatorModule: FunctionComponent<Props & StateProps> = ({
  translatedModule,
  translatedModule: {
    settings: { width, height, icon, lineStyle, lineWeight },
  },
  scheme,
}) => {
  const isFullWidth = width === "full-width";
  const hasIcon = icon !== null;

  return (
    <div
      id={translatedModule.id}
      className={ClassNames(
        "SeparatorModule",
        "Module",
        `SeparatorModule--height-${height}`,
        style({
          background: scheme.main.background,
          color: scheme.main.text,
        }),
      )}
    >
      <div
        className={ClassNames(
          "SeparatorModule__Container",
          `SeparatorModule__Container--width-${width}`,
          {
            Module__Wrapper: !isFullWidth,
          },
        )}
      >
        {hasIcon ? (
          <SeparatorModuleIcons
            translatedModule={translatedModule}
            scheme={scheme}
          />
        ) : (
          <SeparatorLine
            color={scheme.main.text}
            lineStyle={lineStyle}
            lineWeight={lineWeight}
            position="full"
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { colorSchemes, sites },
  { translatedModule },
): StateProps => {
  const site = getActiveSite(sites);

  return {
    scheme: getActiveColorScheme(colorSchemes, site, translatedModule),
  };
};

export default connect(mapStateToProps)(SeparatorModule);
