import { FunctionComponent, useEffect, useState } from "react";
import { ConnectedProps, MapStateToProps, connect } from "react-redux";
import { injectScript } from "../../actions/LoadStates.js";
import { getActiveSite } from "../../selectors/sites.js";
import {
  BaseModuleProps,
  ColorScheme,
  GetavoModuleSettings,
  StoreState,
} from "../../types/index.js";
import {
  getActiveColorScheme,
  getSupportedLanguage,
} from "../../utils/utils.js";
import LazyloadWrapper from "../LazyloadWrapper.js";
import ModuleHeadings from "../ModuleHeadings.js";
import ModuleWithHeadings from "../ModuleWithHeadings.js";

type Props = BaseModuleProps<GetavoModuleSettings>;

interface StateProps {
  scheme: ColorScheme;
}

type ReduxProps = ConnectedProps<typeof connector>;

const GetavoModule: FunctionComponent<Props & ReduxProps> = ({
  scheme,
  translatedModule: {
    id,
    settings: { textAlign, code },
    translation: {
      languageId,
      settings: { title, subtitle },
    },
  },
  isFirstOnPage,
  isPreview,
  injectScript,
}) => {
  const [isLazyloaded, setIsLazyloaded] = useState(false);

  useEffect(() => {
    code && isLazyloaded && injectScript("https://cdn.getavo.it/getavo.js");
  }, [isLazyloaded, isPreview, code]);

  return (
    <ModuleWithHeadings
      title={title}
      subtitle={subtitle}
      id={id}
      className="GetavoModule"
      colors={{
        background: scheme.main.separator,
        color: scheme.main.text,
      }}
    >
      <div className="Module__Wrapper">
        <ModuleHeadings
          scheme={scheme}
          isFirstOnPage={isFirstOnPage}
          textAlign={textAlign}
          title={title}
          subtitle={subtitle}
        />
      </div>

      <LazyloadWrapper onLoad={setIsLazyloaded}>
        <div className="Module__Wrapper">
          {!!code ? (
            <bn-getavo
              id="BN-getavo"
              class="BN-getavo"
              data-lang={getSupportedLanguage(languageId, ["de", "it", "en"])}
              data-code={code}
            />
          ) : (
            <div className="GetavoModule__Empty">Getavo</div>
          )}
        </div>
      </LazyloadWrapper>
    </ModuleWithHeadings>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { colorSchemes, sites },
  { translatedModule },
): StateProps => ({
  scheme: getActiveColorScheme(
    colorSchemes,
    getActiveSite(sites),
    translatedModule,
  ),
});

const mapDispatchToProps = {
  injectScript,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(GetavoModule);
