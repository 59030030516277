import ClassNames from "classnames";
import { FunctionComponent } from "react";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import { style } from "typestyle";
import { getActiveSite } from "../../selectors/sites.js";
import {
  BaseModuleProps,
  ButtonModuleSettings,
  ColorScheme,
  EmbeddedGoogleMapsModuleSettings,
  ImageModuleSettings,
  StoreState,
  TextModuleSettings,
  TranslatedModule,
} from "../../types/index.js";
import {
  getActiveColorScheme,
  getModulesByParentId,
  getTranslatedButtonModules,
  getTranslatedModule,
} from "../../utils/utils.js";
import ModuleHeadings from "../ModuleHeadings.js";
import ModuleWithHeadings from "../ModuleWithHeadings.js";
import RichEditorWrapper from "../RichEditorWrapper.js";
import ButtonModule from "./ButtonModule.js";
import EmbeddedGoogleMapsModule from "./EmbeddedGoogleMapsModule.js";
import ImageModule from "./ImageModule.js";

interface Props extends BaseModuleProps<TextModuleSettings> {}

interface StateProps {
  media:
    | TranslatedModule<ImageModuleSettings | EmbeddedGoogleMapsModuleSettings>
    | undefined;
  scheme: ColorScheme;
  buttons: TranslatedModule<ButtonModuleSettings>[];
}

type ReduxProps = ConnectedProps<typeof connector>;

const TextModule: FunctionComponent<Props & ReduxProps> = ({
  media,
  translatedModule,
  translatedModule: {
    settings: {
      textAlign,
      mediaAspectRatio,
      mediaAlign,
      width,
      boxAlign,
      columns,
    },
    id,
    translation: {
      languageId,
      settings: { subtitle, title },
    },
  },
  buttons,
  scheme,
  isPreview,
  isActive,
  activeModuleId,
  pageId,
  isFirstOnPage,
}) => (
  <ModuleWithHeadings
    title={title}
    subtitle={subtitle}
    id={id}
    colors={{ background: scheme.main.background }}
    className="TextModule"
  >
    <div
      className={ClassNames(
        "Module__Wrapper Module__Wrapper--default-padding",
        "TextModule__Wrapper",
        `FlexAlignH--${boxAlign}`,
      )}
    >
      <div
        className={ClassNames(
          "TextModule__WrapperInner",
          `TextModule__WrapperInner--media-${mediaAlign}`,
          {
            "TextModule__WrapperInner--narrow": width === "narrow",
            "TextModule__WrapperInner--wide": width === "wide",
            "TextModule__WrapperInner--with-image":
              media && checkIsImageModule(media),
            "TextModule__WrapperInner--with-map":
              media && checkIsEmbeddedGoogleMapsModule(media),
          },
        )}
      >
        {media && checkIsImageModule(media) && (
          <div className="TextModule__Media TextModule__Media--image">
            <ImageModule
              aspectRatio={mediaAspectRatio}
              lazyLoad={true}
              translatedModule={media}
              width={600}
              sizes="(min-width: 640px) 40vw, (min-width: 1600px) 600px, 100vw"
              isPreview={isPreview}
              isSlider={false}
              pageId={pageId}
              scheme={scheme}
              showOverlay={false}
            />
          </div>
        )}

        {media && checkIsEmbeddedGoogleMapsModule(media) && (
          <div className="TextModule__Media TextModule__Media--map">
            <EmbeddedGoogleMapsModule
              aspectRatio={mediaAspectRatio}
              isActive={isActive || media.id === activeModuleId}
              isPreview={isPreview}
              translatedModule={media}
            />
          </div>
        )}

        <div
          className={ClassNames(
            "TextModule__Content",
            style({
              color: scheme.main.text,
            }),
          )}
        >
          <div className="TextModule__Text">
            <ModuleHeadings
              scheme={scheme}
              isFirstOnPage={isFirstOnPage}
              textAlign={textAlign}
              title={title}
              subtitle={subtitle}
            />

            <RichEditorWrapper
              className={ClassNames(`TextAlign--${textAlign.description}`, {
                "TextModule__Description--columns-2": columns === 2,
              })}
              module={translatedModule}
              pageId={pageId}
              languageId={languageId}
              isPreview={isPreview}
              scheme={scheme}
            />
          </div>
          {buttons.length > 0 && (
            <div
              className={`ButtonGroup ButtonGroup--align-${textAlign.buttons}`}
            >
              {buttons.map((button) => (
                <ButtonModule
                  key={button.id}
                  scheme={scheme}
                  isPreview={isPreview}
                  translatedModule={button}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  </ModuleWithHeadings>
);

const checkIsImageModule = (
  module: TranslatedModule,
): module is TranslatedModule<ImageModuleSettings> =>
  module.type === "ImageModule";

const checkIsEmbeddedGoogleMapsModule = (
  module: TranslatedModule,
): module is TranslatedModule<EmbeddedGoogleMapsModuleSettings> =>
  module.type === "EmbeddedGoogleMapsModule";

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { modules, colorSchemes, sites },
  {
    translatedModule,
    translatedModule: {
      id: moduleId,
      translation: { languageId },
    },
    pageId,
  },
): StateProps => {
  const site = getActiveSite(sites);

  const media = getModulesByParentId<
    ImageModuleSettings | EmbeddedGoogleMapsModuleSettings
  >(modules, moduleId, pageId)
    .filter(
      ({ type }) =>
        type === "ImageModule" || type === "EmbeddedGoogleMapsModule",
    )
    .map((module) => getTranslatedModule(module, languageId))[0];

  return {
    media,
    buttons: getTranslatedButtonModules({
      modules,
      moduleId,
      languageId,
      pageId,
    }),
    scheme: getActiveColorScheme(colorSchemes, site, translatedModule),
  };
};

const connector = connect(mapStateToProps);

export default connector(TextModule);
