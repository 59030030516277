import { FunctionComponent } from "react";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import tinycolors from "tinycolor2";
import { NestedCSSProperties } from "typestyle/lib/types";
import { getActiveSite } from "../../selectors/sites.js";
import {
  BaseModuleProps,
  ColorScheme,
  HogastGastropoolSmartPayModuleSettings,
  Language,
  SmartPayOptions,
  SmartPayProvider,
  StoreState,
} from "../../types/index.js";
import {
  getActiveColorScheme,
  getSupportedLanguage,
} from "../../utils/utils.js";
import IframeWidget from "../IframeWidget.js";
import ModuleHeadings from "../ModuleHeadings.js";
import ModuleWithHeadings from "../ModuleWithHeadings.js";

type Props = BaseModuleProps<HogastGastropoolSmartPayModuleSettings>;

interface StateProps {
  scheme: ColorScheme;
}

type ReduxProps = ConnectedProps<typeof connector>;

const HogastGastropoolSmartPayModule: FunctionComponent<Props & ReduxProps> = ({
  scheme,
  translatedModule: {
    id,
    settings: { textAlign, key, provider, options },
    translation: {
      languageId,
      settings: { title, subtitle },
    },
  },
  isFirstOnPage,
  isActive,
  isPreview,
}) => {
  const scriptURL = getScriptURL({ languageId, provider, key, options });

  return (
    <ModuleWithHeadings
      title={title}
      subtitle={subtitle}
      id={id}
      className="HogastGastropoolSmartPayModule"
      colors={{
        background: scheme.main.separator,
        color: scheme.main.text,
      }}
    >
      <div className="Module__Wrapper">
        <ModuleHeadings
          scheme={scheme}
          isFirstOnPage={isFirstOnPage}
          textAlign={textAlign}
          title={title}
          subtitle={subtitle}
        />
      </div>

      <div className="Module__Wrapper">
        {!!key && (
          <IframeWidget
            isActive={isActive}
            isPreview={isPreview}
            scriptUrl={scriptURL.toString()}
            style={getStyle(scheme)}
          >
            <div id="hgPaymentWidget" />
          </IframeWidget>
        )}

        {!key && (
          <div className="HogastGastropoolSmartPayModule__Empty">
            hogast/gastropool Smart Pay
          </div>
        )}
      </div>
    </ModuleWithHeadings>
  );
};

const getScriptURL = ({
  languageId,
  provider,
  key,
  options: { deposit, travelInsurance },
}: {
  languageId: Language;
  provider: SmartPayProvider;
  key: string;
  options: SmartPayOptions;
}): URL => {
  const origins: { [provider in SmartPayProvider]: string } = {
    hogast: "https://secure.hogast.it",
    gastropool: "https://secure.gastropool.it",
  };

  const lang = getSupportedLanguage(languageId, ["de", "it", "en"]);
  const url = new URL(`${origins[provider]}/rxwidgets/payment/script/${lang}`);
  url.searchParams.set("key", key);
  url.searchParams.set("ActivateDeposit", deposit ? "True" : "False");
  url.searchParams.set("ActivateTravel", travelInsurance ? "True" : "False");

  return url;
};

const getStyle = (scheme: ColorScheme): NestedCSSProperties => {
  const textAndBackground = {
    background: scheme.primary.background,
    color: scheme.primary.text,
  };

  const backgroundAsColor = {
    color: scheme.primary.background,
  };

  const borderColor = {
    borderColor: scheme.primary.background,
  };

  return {
    $nest: {
      ".hgPaymentWidget": {
        background: scheme.main.background,
        color: scheme.main.text,
        maxWidth: "1200px",
        margin: "0 auto",
        padding: "3em 6%",

        $nest: {
          ".hgpButton.hgpButtonPrimary": textAndBackground,
          ".hgpProgress .hgpStepActive .hgpRect": textAndBackground,
          ".hgpProgress .hgpLabel": backgroundAsColor,
          ".hgpFormElement .hgpCheckBox": backgroundAsColor,
          ".hgpProgress": {
            borderBottomColor: scheme.primary.background,
          },
          ".hgpFormElement .hgpCheckBox input[type=checkBox] + span::after":
            borderColor,
          ".hgpFormElement .hgpCheckBox input[type=checkBox] + span::before":
            borderColor,
          ".hgpFormElement .hgpRadioButton input[type=radio] + span::before":
            borderColor,
          ".hgpFormElement .hgpRadioButton": backgroundAsColor,
          ".hgpProgress .hgpStep:not(:last-child) .hgpStepInner > div:last-child::before":
            {
              borderTopColor: tinycolors(scheme.primary.background)
                .setAlpha(0.5)
                .toRgbString(),
            },
          ".hgpProgress .hgpRect": {
            borderColor: scheme.primary.background,
            color: tinycolors(scheme.primary.background)
              .setAlpha(0.5)
              .toRgbString(),
          },
          ".hgpForm .hgpFormRow.hgpHighlight": textAndBackground,
          ".hgpForm .hgpFormRow": {
            background: scheme.secondary.background,
            color: scheme.secondary.text,
          },
          ".hgpFooter .hgpFooterHeader": backgroundAsColor,
          ".hgpFormAmountCalculator .hgpBooked": {
            background: tinycolors(scheme.primary.background)
              .lighten(20)
              .toRgbString(),
            color: scheme.primary.text,
          },
          ".hgpFormAmountCalculator .hgpAmount": textAndBackground,
          ".hgpPersonsInfo": backgroundAsColor,
          ".hgpText.hgpInfo a": backgroundAsColor,
          ".hgpPrivacyCheckbox span a": backgroundAsColor,
          ".hgpAdditionalInfo a": backgroundAsColor,
          ".hgpExpander": backgroundAsColor,
          ".hgpFooterImpressum a": backgroundAsColor,
          ".hgpFooterAgent a": backgroundAsColor,
        },
      },
    },
  };
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { colorSchemes, sites },
  { translatedModule },
): StateProps => ({
  scheme: getActiveColorScheme(
    colorSchemes,
    getActiveSite(sites),
    translatedModule,
  ),
});

const connector = connect(mapStateToProps);

export default connector(HogastGastropoolSmartPayModule);
