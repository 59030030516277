import { AllActions } from "../actions/index.js";
import { Alert } from "../types/index.js";

const initialState: Alert[] = [];

const showAlert = (
  state: Alert[],
  text: string,
  type: Alert["type"] = "error",
): Alert[] => {
  const alert: Alert = {
    id: crypto.randomUUID(),
    text,
    type,
  };
  return state.concat(alert);
};

const removeAlert = (state: Alert[], id: string): Alert[] =>
  state.filter((alert) => alert.id !== id);

const reducer = (state = initialState, action: AllActions) => {
  switch (action.type) {
    case "SHOW_ALERT":
      return showAlert(state, action.text, action.alertType);

    case "REMOVE_ALERT":
      return removeAlert(state, action.id);

    default:
      return state;
  }
};

export default reducer;
