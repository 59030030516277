import ClassNames from "classnames";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { ConnectedProps, MapStateToProps, connect } from "react-redux";
import { RemoveScroll } from "react-remove-scroll";
import { useHistory } from "react-router";
import { style } from "typestyle";
import { getActiveSite } from "../../selectors/sites.js";
import {
  BaseModuleProps,
  ButtonModuleSettings,
  ColorScheme,
  ImageModuleSettings,
  Language,
  Modules,
  OfferingsModuleSettings,
  PopUpModuleSettings,
  StoreState,
  TranslatedModule,
} from "../../types/index.js";
import { useKeyDown } from "../../utils/hooks.js";
import {
  getActiveColorScheme,
  getImageSubModule,
  getModuleSettingsCloseLink,
  getModulesByParentId,
  getTranslatedButtonModules,
  getTranslatedModule,
} from "../../utils/utils.js";
import Icon from "../Icon.js";
import ModuleHeadings from "../ModuleHeadings.js";
import ModuleWithHeadings from "../ModuleWithHeadings.js";
import RichEditorWrapper from "../RichEditorWrapper.js";
import ButtonModule from "./ButtonModule.js";
import ImageModule from "./ImageModule.js";
import SpecialsModule from "./SpecialsModule.js";

/**
 * Detect if the user has been inactive for 60 seconds
 */
const onInactiveUser = (callback: () => void) => {
  let timerHandle: number | undefined = undefined;

  const removeListeners = () => {
    window.clearTimeout(timerHandle);
    eventNames.forEach((eventName) =>
      document.removeEventListener(eventName, resetTimer),
    );
  };

  const resetTimer = () => {
    window.clearTimeout(timerHandle);
    timerHandle = window.setTimeout(() => {
      removeListeners();
      callback();
    }, 60e3);
  };

  resetTimer();

  const eventNames: (keyof GlobalEventHandlersEventMap)[] = [
    "mousemove",
    "click",
    "touchstart",
    "keypress",
    "scroll",
    "resize",
  ];

  eventNames.forEach((eventName) =>
    document.addEventListener(eventName, resetTimer, { passive: true }),
  );

  return {
    removeListeners,
  };
};

type Props = BaseModuleProps<PopUpModuleSettings>;

interface StateProps {
  image: TranslatedModule<ImageModuleSettings> | undefined;
  scheme: ColorScheme;
  buttons: TranslatedModule<ButtonModuleSettings>[];
  specialsModule: TranslatedModule<OfferingsModuleSettings> | undefined;
  isActiveRecursive: boolean;
  isEditing: boolean;
}

type ReduxProps = ConnectedProps<typeof connector>;

const getIsPopUpClosed = (moduleId: string) => {
  try {
    return !!window.sessionStorage.getItem(`popUpClosed-${moduleId}`);
  } catch (error) {
    return true;
  }
};

const setIsPopUpClosed = (moduleId: string) => {
  try {
    window.sessionStorage.setItem(`popUpClosed-${moduleId}`, "true");
  } catch (error) {
    // Do nothing if cookies / sessionStorage are disabled
  }
};

const checkHasTextContent = ({
  translation: {
    settings: { description, subtitle, title },
  },
}: TranslatedModule<PopUpModuleSettings>) =>
  Boolean(description || subtitle || title);

const PopUpModule: FunctionComponent<Props & ReduxProps> = ({
  isPreview,
  scheme,
  translatedModule,
  translatedModule: {
    id: moduleId,
    siteId,
    settings: {
      textAlign,
      mediaAspectRatio,
      darkBackground,
      boxAlign,
      imageAlign,
      onlyShowWhenUserInactive,
    },
    translation: {
      languageId,
      settings: { title, subtitle },
    },
  },
  pageId,
  isFirstOnPage,
  image,
  buttons,
  specialsModule,
  isActiveRecursive,
  isEditing,
  queries,
  activeModuleId,
  isActive,
}) => {
  const history = isPreview ? useHistory() : undefined;
  const [isOpenState, setIsOpenState] = useState(false);
  const isOpen = isPreview ? isActiveRecursive : isOpenState;
  const popUpRef = useRef<HTMLDivElement>(null);
  const moduleRef = useRef<HTMLDivElement>(null);
  const isEditingRef = useRef(false);
  isEditingRef.current = isEditing;

  const closePopup = () => {
    setIsOpenState(false);
    removeListeners();
    history?.push(
      getModuleSettingsCloseLink({
        languageId,
        pageId,
        parentId: null,
        moduleType: "PopUpModule",
        siteId,
      }),
    );
  };

  useKeyDown("Escape", closePopup);

  const onClick = ({ target }: MouseEvent) => {
    if (
      !(target instanceof Element) ||
      !popUpRef.current ||
      !moduleRef.current
    ) {
      return;
    }

    const isClickInsidePopUp = popUpRef.current.contains(target);
    const isClickOutsidePopUp =
      !isClickInsidePopUp && moduleRef.current.contains(target);
    const anchorElement = target.closest<HTMLAnchorElement>("a");

    // Only trigger for links which point to a hash or an URL. Don’t trigger on
    // placeholder links (href="" or href="#"), therefore it is accessed with
    // .getAttribute("href").
    const isLink =
      !!anchorElement?.href && anchorElement.getAttribute("href") !== "#";
    const isClickOnLink = isClickInsidePopUp && isLink;

    (isClickOutsidePopUp || (isClickOnLink && !isEditingRef.current)) &&
      closePopup();
  };

  const removeListeners = () => {
    document.removeEventListener("click", onClick);
  };

  useEffect(() => {
    const openPopUp = () => {
      setIsOpenState(true);
      setIsPopUpClosed(moduleId);
    };

    const isPopUpClosed = getIsPopUpClosed(moduleId);

    const onInactiveHandle =
      !isPreview && !isPopUpClosed && onlyShowWhenUserInactive
        ? onInactiveUser(openPopUp)
        : undefined;

    !isPreview && !isPopUpClosed && !onlyShowWhenUserInactive && openPopUp();

    document.addEventListener("click", onClick, { passive: true });

    return () => {
      removeListeners();
      onInactiveHandle?.removeListeners();
    };
  }, []);

  if (!isOpen) return null;

  return (
    <RemoveScroll noIsolation={isPreview}>
      <ModuleWithHeadings
        title={title}
        subtitle={subtitle}
        id={moduleId}
        colors={undefined}
        className={ClassNames("PopUpModule", {
          [`PopUpModule--image-${imageAlign}`]: !!image,
          "PopUpModule--dark-background": darkBackground,
        })}
        innerRef={moduleRef}
      >
        <div className="PopUpModule__ScrollContainerOuter">
          <div
            className={ClassNames(
              "PopUpModule__ScrollContainer",
              `PopUpModule__ScrollContainer--align-${boxAlign}`,
            )}
          >
            <div
              ref={popUpRef}
              className={ClassNames(
                "PopUpModule__Container",
                `PopUpModule__Container--align-${boxAlign}`,
                style({
                  backgroundColor: scheme.main.background,
                  color: scheme.main.text,
                }),
              )}
            >
              <button
                className={ClassNames(
                  "Btn--bare",
                  "PopUpModule__CloseButton",
                  style({
                    fill: scheme.primary.text,
                    backgroundColor: scheme.primary.background,
                  }),
                )}
                onClick={() => closePopup()}
                type="button"
              >
                <Icon glyph="close" />
              </button>
              {image && (
                <div className="PopUpModule__Image">
                  <ImageModule
                    aspectRatio={mediaAspectRatio}
                    lazyLoad={true}
                    translatedModule={image}
                    width={600}
                    sizes="(min-width: 640px) 40vw, (min-width: 1600px) 600px, 100vw"
                    isPreview={isPreview}
                    isSlider={false}
                    pageId={pageId}
                    scheme={scheme}
                    showOverlay={false}
                  />
                </div>
              )}
              {checkHasTextContent(translatedModule) && (
                <div className="PopUpModule__Content">
                  <div className="PopUpModule__Text">
                    <ModuleHeadings
                      scheme={scheme}
                      isFirstOnPage={isFirstOnPage}
                      textAlign={textAlign}
                      title={title}
                      subtitle={subtitle}
                    />

                    <RichEditorWrapper
                      module={translatedModule}
                      pageId={pageId}
                      languageId={languageId}
                      isPreview={isPreview}
                      scheme={scheme}
                      className={`PopUpModule__Description TextAlign--${textAlign.description}`}
                    />
                  </div>

                  {buttons.length > 0 && (
                    <div
                      className={ClassNames(
                        "PopUpModule__Buttons",
                        "ButtonGroup",
                        `ButtonGroup--align-${textAlign.buttons}`,
                        {
                          "PopUpModule__Buttons--over-image":
                            !!image && imageAlign === "bottom",
                        },
                      )}
                    >
                      {buttons.map((button) => (
                        <ButtonModule
                          key={button.id}
                          scheme={scheme}
                          isPreview={isPreview}
                          translatedModule={button}
                        />
                      ))}
                    </div>
                  )}
                  {specialsModule && (
                    <div className="PopUpModule__Special">
                      <SpecialsModule
                        isFirstOnPage={true}
                        translatedModule={specialsModule}
                        queries={queries}
                        activeModuleId={activeModuleId}
                        isPreview={isPreview}
                        pageId={pageId}
                        isActive={isActive}
                        scheme={scheme}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </ModuleWithHeadings>
    </RemoveScroll>
  );
};

const getSpecialsModule = ({
  modules,
  parentId,
  pageId,
  languageId,
}: {
  modules: Modules;
  parentId: string;
  pageId: string | null;
  languageId: Language;
}) => {
  const specialsModules = getModulesByParentId<OfferingsModuleSettings>(
    modules,
    parentId,
    pageId,
    "SpecialsModule",
  );

  return specialsModules.length
    ? getTranslatedModule(specialsModules[0], languageId)
    : undefined;
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { colorSchemes, sites, modules, richEditor },
  {
    translatedModule,
    translatedModule: {
      id: moduleId,
      translation: { languageId },
      pageId,
    },
    activeModuleId,
  },
): StateProps => {
  const site = getActiveSite(sites);

  const image = getImageSubModule({ languageId, pageId, moduleId, modules });
  const buttons = getTranslatedButtonModules({
    modules,
    moduleId,
    languageId,
    pageId,
  });

  const specialsModule = getSpecialsModule({
    modules,
    pageId,
    parentId: moduleId,
    languageId,
  });

  const isActiveRecursive =
    activeModuleId !== undefined &&
    [
      ...buttons.map(({ id }) => id),
      image?.id,
      moduleId,
      specialsModule?.id,
    ].includes(activeModuleId);

  return {
    image,
    buttons,
    specialsModule,
    scheme: getActiveColorScheme(colorSchemes, site, translatedModule),
    isActiveRecursive,
    isEditing: !!richEditor[moduleId],
  };
};

const connector = connect(mapStateToProps);

export default connector(PopUpModule);
