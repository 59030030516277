import ClassNames from "classnames";
import { FunctionComponent, RefCallback } from "react";
import { CommonHeaderLayoutProps } from "../types/index.js";
import {
  getActionAndContactLinks,
  getImageModuleWithFixedAspectRatio,
} from "../utils/utils.js";
import HeaderLogo from "./HeaderLogo.js";
import Header4And5Mobile from "./HeaderMobile/Header4And5Mobile.js";
import HeaderSidebarIconNav from "./HeaderSidebarIconNav.js";
import LanguageMenu from "./LanguageMenu.js";
import LeftOverlayNav from "./LeftOverlayNav.js";
import ImagesModule from "./Modules/ImagesModule.js";

interface Props {
  intersectionTriggerRef: RefCallback<HTMLDivElement>;
  common: CommonHeaderLayoutProps;
  showMenuSeparators: boolean;
}

const HeaderLayout4: FunctionComponent<Props> = ({
  showMenuSeparators,
  intersectionTriggerRef,
  common,
  common: {
    accommodation,
    actionLinks,
    activePagePath,
    fallbackLanguageId,
    imageOverlayGradient,
    imagesModule,
    isPreview,
    languageId,
    logo,
    logoSize,
    mainPageURL,
    menuItems,
    pageId,
    pages,
    queries,
    scheme,
  },
}) => {
  const navLinks = getActionAndContactLinks(
    accommodation,
    actionLinks,
    languageId,
  );

  return (
    <>
      {imagesModule?.pageId && (
        <div
          className={ClassNames(
            "HeaderModule__Inner",
            "HeaderModule__Inner--wide",
            "HeaderModule__Inner--left-overlay-nav",
            `HeaderModule__Inner--image-overlay-gradient-${imageOverlayGradient}`,
            {
              "HeaderModule__Inner--full-height": !isPreview,
            },
          )}
        >
          <ImagesModule
            className="FullScreenHeaderImages"
            translatedModule={getImageModuleWithFixedAspectRatio(imagesModule)}
            isPreview={isPreview}
            pageId={pageId}
            queries={queries}
            isActive={false}
            isInsideHeader={true}
            isFirstOnPage={false}
            parentScheme={scheme}
            activeModuleId={undefined}
            fullHeight={!isPreview}
          />
          <div
            ref={intersectionTriggerRef}
            className={ClassNames("LeftHeaderOverlay", {
              "LeftHeaderOverlay--show-menu-separators": showMenuSeparators,
            })}
          >
            <LeftOverlayNav
              isPreview={isPreview}
              languageId={languageId}
              pages={pages}
              activePagePath={activePagePath}
              fallbackLanguageId={fallbackLanguageId}
              menuItems={menuItems}
            />
            <div className="LeftHeaderOverlay__Box">
              <HeaderLogo
                isPreview={isPreview}
                logo={logo}
                mainPageURL={mainPageURL}
                scheme={scheme}
                maxSize={{ width: 300, height: 100 }}
                size={logoSize}
              />
              <div className="FullHeaderOverlay__Box LeftHeaderOverlay__BoxInfo">
                <HeaderSidebarIconNav
                  isPreview={isPreview}
                  navLinks={navLinks}
                  scheme={scheme}
                  hoverTextDirection="left"
                />
                <LanguageMenu
                  isPreview={isPreview}
                  languageId={languageId}
                  scheme={undefined}
                  pageId={pageId}
                  variant="horizontal"
                  switchToSelectLimit={1}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <Header4And5Mobile {...common} />
    </>
  );
};

export default HeaderLayout4;
