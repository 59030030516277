import ClassNames from "classnames";
import { FunctionComponent, MouseEventHandler } from "react";
import { LogoBackground } from "../../server/types/index.js";
import { PictureCategory } from "../types/index.js";
import Icon from "./Icon.js";

interface Props {
  url: string;
  onSelection?: MouseEventHandler<HTMLDivElement>;
  title?: string | null;
  isSelected?: boolean;
  imageCount?: number;
  category?: PictureCategory;
  background?: LogoBackground;
  width?: number;
  height?: number;
}

const MediaLibraryImage: FunctionComponent<Props> = ({
  title,
  url,
  isSelected = false,
  imageCount = 1,
  onSelection,
  category = "pictures",
  background = "light",
  width,
  height,
}) => {
  return (
    <div
      className={ClassNames(
        "MediaLibrary__Image",
        `MediaLibrary__Image--${category}`,
        `MediaLibrary__Image--${background}`,
        {
          "MediaLibrary__Image--selected": isSelected,
        },
      )}
      onClick={onSelection}
    >
      <img
        width={width}
        height={height}
        loading="lazy"
        className="FullImage"
        src={url}
        alt={title || ""}
        title={title || undefined}
      />

      {imageCount > 1 && (
        <div className="MediaLibrary__Image__Count">
          <span>+{imageCount}</span>
        </div>
      )}

      {isSelected && (
        <div className="MediaLibrary__Image__Check">
          <Icon glyph="check" />
        </div>
      )}
    </div>
  );
};

export default MediaLibraryImage;
