import ClassNames from "classnames";
import { FunctionComponent } from "react";
import { style } from "typestyle";
import { ColorScheme, NavLinkWithIcon } from "../types/index.js";
import Icon from "./Icon.js";
import SmartLink from "./SmartLink.js";

interface Props {
  scheme: ColorScheme;
  navLinks: NavLinkWithIcon[];
  isPreview: boolean;
  hoverTextDirection: "left" | "right";
}

const HeaderSidebarIconNav: FunctionComponent<Props> = ({
  scheme,
  navLinks,
  isPreview,
  hoverTextDirection,
}) => {
  if (!navLinks.length) return null;

  return (
    <ul
      className={ClassNames(
        "HeaderSidebarIconNav",
        style({
          color: scheme.primary.text,
        }),
      )}
    >
      {navLinks.map(({ href, icon, children }, index) => (
        <li key={index} className={"HeaderSidebarIconNav__ListItem"}>
          <SmartLink
            anchorAttrs={{
              className: "HeaderSidebarIconNav__Item",
              href,
            }}
            isPreview={isPreview}
          >
            <Icon
              className={ClassNames(
                "HeaderSidebarIconNav__Icon",
                style({
                  fill: scheme.main.text,
                }),
              )}
              glyph={icon}
            />
            <span
              className={ClassNames(
                "HeaderSidebarIconNav__Text",
                {
                  "HeaderSidebarIconNav__Text--left":
                    hoverTextDirection === "left",
                },
                style({
                  backgroundColor: scheme.primary.background,
                  $nest: {
                    "&::before": {
                      [hoverTextDirection === "right"
                        ? "borderRightColor"
                        : "borderLeftColor"]: scheme.primary.background,
                    },
                  },
                }),
              )}
            >
              {children}
            </span>
          </SmartLink>
        </li>
      ))}
    </ul>
  );
};

export default HeaderSidebarIconNav;
