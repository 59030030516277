import { AllActions } from "../actions/index.js";
import { Font, Fonts, FontsByFamily } from "../types/index.js";

const initialState: Fonts = {
  byFamily: {},
  allFamilies: [],
};

const getFontsSuccess = (state: Fonts, fonts: Font[]): Fonts => {
  const allIds = fonts.map(({ family: id }) => id);
  const byId: FontsByFamily = fonts.reduce<FontsByFamily>(
    (acc, font) => ({ ...acc, [font.family]: font }),
    {},
  );

  return { ...state, byFamily: byId, allFamilies: allIds };
};

const reducer = (state: Fonts = initialState, action: AllActions) => {
  switch (action.type) {
    case "GET_FONTS_SUCCESS":
      return getFontsSuccess(state, action.fonts);

    default:
      return state;
  }
};

export default reducer;
