import ClassNames from "classnames";
import {
  createElement,
  FunctionComponent,
  useEffect,
  useRef,
  useState,
} from "react";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import { style } from "typestyle";
import { toggleLegalNav } from "../actions/LegalNav.js";
import { getPageModules } from "../selectors/modules.js";
import {
  AllModulesByType,
  BaseModuleProps,
  ColorScheme,
  ContainerQueries,
  Language,
  Module,
  StoreState,
  ThunkDispatch,
} from "../types/index.js";
import { mostModulesByType } from "../utils/mostModulesByType.js";
import { getTranslatedModule, scrollToElSingle } from "../utils/utils.js";
import Icon from "./Icon.js";

interface Props {
  colorScheme: ColorScheme;
  languageId: Language;
  isPreview: boolean;
  queries: ContainerQueries;
  isActive: boolean;
  isFirstOnPage: boolean;
  siteId: string;
}

interface StateProps {
  pageId: string | undefined;
  isOpen: boolean;
  modules: Module[];
}

type ReduxProps = ConnectedProps<typeof connector>;

type DispatchProps = {
  onClose: () => void;
};

const getModulesByType = async (): Promise<AllModulesByType> => {
  const { systemPageModulesByType } = await import(
    "../utils/systemPageModulesByType.js"
  );

  return {
    ...mostModulesByType,
    ...systemPageModulesByType,
  };
};

const InlineTerms: FunctionComponent<Props & ReduxProps> = ({
  modules,
  onClose,
  colorScheme,
  queries,
  isOpen,
  isPreview,
  pageId,
  languageId,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [modulesByType, setModulesByType] = useState<AllModulesByType>();

  useEffect(() => {
    pageId && getModulesByType().then((result) => setModulesByType(result));
  }, [pageId]);

  useEffect(() => {
    pageId &&
      ref.current &&
      modulesByType &&
      scrollToElSingle(ref.current, {
        block: "start",
        inline: "start",
      });
  }, [pageId, modulesByType]);

  if (!pageId || !isOpen) return null;

  return (
    <div
      className={ClassNames(
        "InlineTerms",
        style({ borderColor: colorScheme.primary.background }),
      )}
      ref={ref}
    >
      <div className="InlineTerms__Content">
        {modulesByType &&
          modules.map((module) =>
            createElement<BaseModuleProps>(modulesByType[module.type], {
              key: module.id,
              translatedModule: getTranslatedModule(module, languageId),
              pageId,
              queries,
              isPreview,
              isActive: false,
              isFirstOnPage: false,
              activeModuleId: undefined,
            }),
          )}
      </div>
      <button
        className={ClassNames(
          "InlineTerms__Close",
          style({
            background: colorScheme.primary.background,
            fill: colorScheme.primary.text,
            color: colorScheme.primary.text,
          }),
        )}
        onClick={onClose}
      >
        <Icon glyph="close" />
      </button>
    </div>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = ({
  pages,
  modules,
  legalNav,
}): StateProps => {
  const pageId = legalNav.id ? pages.byAlias[legalNav.id] : undefined;
  return {
    pageId,
    isOpen: !!legalNav.id,
    modules: pageId ? getPageModules(modules, pageId) : [],
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  onClose: () => dispatch(toggleLegalNav(undefined)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(InlineTerms);
