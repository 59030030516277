import { FunctionComponent } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { ColorSchemesAPI } from "../../server/types/index.js";
import { changeModule } from "../actions/Modules.js";
import { Language, StoreState, TranslatedModule } from "../types/index.js";
import { getURL } from "../utils/utils.js";
import ColorSchemeSelection from "./ColorSchemeSelection.js";

interface Props {
  translatedModule: TranslatedModule;
  pageId: string;
}

type RouterProps = RouteComponentProps<{
  siteId: string;
  languageId: Language;
}>;

type StateProps = ColorSchemesAPI;

type ReduxProps = ConnectedProps<typeof connector>;

const ModuleColorSchemeSelection: FunctionComponent<
  Props & ReduxProps & RouterProps
> = ({
  pageId,
  translatedModule: { id: moduleId, colorSchemeId, parentId },
  match: {
    params: { siteId, languageId },
  },
  changeModule,
  history,
}) => {
  // Nested modules can't set their own color scheme
  if (parentId) return null;

  return (
    <ColorSchemeSelection
      siteId={siteId}
      activeId={colorSchemeId}
      onEdit={(id) => {
        history.push(
          getURL(
            siteId,
            "pages",
            pageId,
            languageId,
            "modules",
            moduleId,
            "design",
            "color-schemes",
            id,
          ),
        );
      }}
      onSelection={(id) =>
        changeModule(siteId, moduleId, {
          colorSchemeId: id,
        })
      }
      onSetDefault={() =>
        changeModule(siteId, moduleId, {
          colorSchemeId: null,
        })
      }
    />
  );
};

const mapStateToProps = ({ colorSchemes }: StoreState): StateProps =>
  colorSchemes;

const mapDispatchToProps = { changeModule };

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(ModuleColorSchemeSelection));
