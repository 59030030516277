import ClassNames from "classnames";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { ConnectedProps, MapStateToProps, connect } from "react-redux";
import { getActiveSite } from "../../selectors/sites.js";
import {
  BaseModuleProps,
  ColorScheme,
  HTMLModuleSettings,
  StoreState,
} from "../../types/index.js";
import { getActiveColorScheme, isDefined } from "../../utils/utils.js";
import LazyloadWrapper from "../LazyloadWrapper.js";
import ModuleHeadings from "../ModuleHeadings.js";
import ModuleWithHeadings from "../ModuleWithHeadings.js";

type Props = BaseModuleProps<HTMLModuleSettings>;

interface StateProps {
  scheme: ColorScheme;
}

type ReduxProps = ConnectedProps<typeof connector>;

const HTMLModule: FunctionComponent<Props & ReduxProps> = ({
  scheme,
  translatedModule: {
    id,
    settings: { textAlign },
    translation: {
      settings: { title, subtitle, html },
    },
  },
  isFirstOnPage,
}) => {
  const [isLazyloaded, setIsLazyloaded] = useState(false);
  const [processedHTML, setProcessedHTML] = useState<string>();
  const scriptURLsRef = useRef<string[]>([]);
  const insertedScriptsRef = useRef<HTMLScriptElement[]>([]);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!html || !isLazyloaded) return;
    const extracted = extractHTMLAndScriptURLs(html);
    scriptURLsRef.current = extracted.scriptURLs;
    setProcessedHTML(extracted.html);
  }, [html, isLazyloaded]);

  // Insert scripts (and re-insert them on changes) after the user input HTML
  // has been rendered.
  useEffect(() => {
    if (processedHTML === undefined) return;
    insertedScriptsRef.current = scriptURLsRef.current.map(insertScript);
  }, [processedHTML]);

  useEffect(() => {
    return () => {
      // Clean up all inserted scripts.
      insertedScriptsRef.current.forEach((el) => el.remove());
      insertedScriptsRef.current = [];
      scriptURLsRef.current.forEach(URL.revokeObjectURL);
    };
  }, []);

  return (
    <ModuleWithHeadings
      title={title}
      subtitle={subtitle}
      id={id}
      className="HTMLModule"
      colors={{
        background: scheme.main.background,
        color: scheme.main.text,
      }}
    >
      <div className="Module__Wrapper">
        <ModuleHeadings
          scheme={scheme}
          isFirstOnPage={isFirstOnPage}
          textAlign={textAlign}
          title={title}
          subtitle={subtitle}
        />
      </div>
      <LazyloadWrapper onLoad={setIsLazyloaded}>
        <div
          ref={contentRef}
          className={ClassNames("Module__Wrapper HTMLModule__Content", {
            "HTMLModule__Content--empty": !html,
          })}
          dangerouslySetInnerHTML={{
            __html: processedHTML ?? "",
          }}
        />
      </LazyloadWrapper>
    </ModuleWithHeadings>
  );
};

/**
 * Process the HTML of this module with DOM APIs:
 * - Extract script URLs (for both external JS files and inline scripts).
 * - Remove the script tags from the resulting HTML.
 *
 * Remember to revoke the resulting object URLs.
 */
const extractHTMLAndScriptURLs = (
  html: string,
): { html: string; scriptURLs: string[] } => {
  const div = document.createElement("div");
  div.innerHTML = html.trim();

  const scriptElements = Array.from(div.querySelectorAll("script"));

  const urls = scriptElements
    .map(({ src, innerText }) => {
      switch (true) {
        case Boolean(src):
          return src;
        case Boolean(innerText):
          return URL.createObjectURL(
            new Blob([innerText], { type: "text/javascript" }),
          );
        default:
          return undefined;
      }
    })
    .filter(isDefined);

  for (const el of scriptElements) {
    el.remove();
  }

  return {
    html: div.innerHTML,
    scriptURLs: urls,
  };
};

const insertScript = (url: string): HTMLScriptElement => {
  const script = document.createElement("script");
  script.src = url;
  script.defer = true;
  document.head.appendChild(script);
  return script;
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { colorSchemes, sites },
  { translatedModule },
): StateProps => ({
  scheme: getActiveColorScheme(
    colorSchemes,
    getActiveSite(sites),
    translatedModule,
  ),
});

const connector = connect(mapStateToProps);

export default connector(HTMLModule);
