import ClassNames from "classnames";
import { FunctionComponent, useState } from "react";
import {
  ColorScheme,
  SeparatorModuleSettings,
  TranslatedModule,
} from "../types/index.js";
import LazyloadWrapper from "./LazyloadWrapper.js";
import SeparatorIcon from "./SeparatorIcon.js";
import SeparatorLine from "./SeparatorLine.js";

interface Props {
  translatedModule: TranslatedModule<SeparatorModuleSettings>;
  scheme: ColorScheme;
}

const SeparatorModuleIcons: FunctionComponent<Props> = ({
  translatedModule: {
    settings: { icon, iconCount, iconSize, width, lineWeight, lineStyle },
  },
  scheme,
}) => {
  const [isLazyloaded, setIsLazyloaded] = useState(false);

  const isFullWidth = width === "full-width";
  const hasLine = lineStyle !== "none";
  const count = isFullWidth ? 100 : iconCount;
  const color = scheme.main.text;

  return (
    <>
      {hasLine && (
        <SeparatorLine
          lineStyle={lineStyle}
          lineWeight={lineWeight}
          color={color}
          position="left"
        />
      )}

      <LazyloadWrapper
        onLoad={setIsLazyloaded}
        className={ClassNames(
          "SeparatorModule__IconWrap",
          `SeparatorModule__IconWrap--size-${iconSize}`,
          {
            "SeparatorModule__IconWrap--margins": !isFullWidth,
          },
        )}
      >
        {isLazyloaded &&
          Array.apply<null, any, number[]>(null, { length: count })
            .map<number>(Number.call, Number)
            .map((index) =>
              icon === null ? null : (
                <SeparatorIcon
                  key={index}
                  className="SeparatorModule__Icon"
                  glyph={icon}
                />
              ),
            )}
      </LazyloadWrapper>

      {hasLine && (
        <SeparatorLine
          lineStyle={lineStyle}
          lineWeight={lineWeight}
          color={color}
          position="right"
        />
      )}
    </>
  );
};

export default SeparatorModuleIcons;
