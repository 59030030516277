import deepExtend from "deep-extend";
import { AllActions } from "../../actions/index.js";
import {
  BookingWidgetFieldSettings,
  ButtonModuleSettings,
  DefaultModuleSettings,
  EnquiryWidgetFieldSettings,
  FooterModuleSettings,
  ImageCropParams,
  Language,
  LinkData,
  LogoCategory,
  Module,
  ModulesById,
  ModuleSettingsTypes,
  ModuleTranslation,
  ModuleTranslations,
  NewsletterModuleFieldSettings,
  OfferingsModuleSettings,
  Page,
  PartialModuleSettings,
  Point,
  PostModuleParams,
  QuickEnquiryWidgetFieldSettings,
  TextAligns,
  TitleAndSubtitleTextAlign,
} from "../../types/index.js";
import {
  getModulesFromAllPages,
  keys,
  ModuleNotFoundError,
  moveArrayElement,
  translateModuleSettings,
} from "../../utils/utils.js";

export const initialState: ModulesById = {};

export const defaultImportantImagePoint: Point = { x: 0.5, y: 0.5 };
export const defaultImageCropParams: ImageCropParams = {
  x: 0,
  y: 0,
  width: 1,
  height: 1,
};

const isFooterModule = (
  module: Module,
): module is Module<FooterModuleSettings> => module.type === "FooterModule";

const linkData: LinkData = {
  url: null,
  moduleId: null,
  moduleType: null,
  pageId: null,
  languageId: null,
};

const buttonModuleSettings: ButtonModuleSettings = {
  global: {
    layout: "flat",
    corner: "square",
    type: "primary",
  },
  language: {
    title: "Button",
    linkData,
  },
};

export const defaultFieldSettings: {
  BookingModule: BookingWidgetFieldSettings;
  EnquiryModule: EnquiryWidgetFieldSettings;
  QuickEnquiryModule: QuickEnquiryWidgetFieldSettings;
  NewsletterModule: NewsletterModuleFieldSettings;
} = {
  BookingModule: {
    gender: "optional",
    phone: "optional",
    street: "disabled",
    zipcode: "disabled",
    city: "disabled",
    country: "optional",
    note: "optional",
  },
  EnquiryModule: {
    stay: "required",
    occupancies: "required",
    gender: "optional",
    phone: "optional",
    street: "disabled",
    zipcode: "disabled",
    city: "disabled",
    country: "optional",
    note: "optional",
    newsletter: "optional",
  },
  QuickEnquiryModule: {
    stay: "required",
    guests: "required",
    gender: "optional",
  },
  NewsletterModule: {
    gender: "optional",
    firstName: "optional",
    lastName: "optional",
    country: "optional",
  },
};

const textAlign: TextAligns = {
  title: "center",
  subtitle: "center",
  description: "center",
  buttons: "center",
};

const highlightTextAlign: TextAligns = {
  title: "left",
  subtitle: "left",
  description: "left",
  buttons: "right",
};

const titleAndSubtitleTextAlign: TitleAndSubtitleTextAlign = {
  title: textAlign.title,
  subtitle: textAlign.subtitle,
};

const offeringModuleSettings: OfferingsModuleSettings = {
  language: {
    title: "",
    subtitle: "",
  },
  global: {
    textAlign: titleAndSubtitleTextAlign,
    layout: "layout_1",
    displayType: "tiles",
    mediaAspectRatio: 1.3333,
    maxColumns: 4,
    pinnedItems: [],
    maxItems: undefined,
    enquiryLayout: "layout_1",
    bookingLayout: "compact",
  },
};

export const defaultSettings: DefaultModuleSettings = {
  HeaderModule: {
    language: {
      activeActionModuleIds: {},
      links: {
        vouchers: linkData,
        specials: linkData,
      },
    },
    global: {
      layoutVariant: "all-in-header",
      showBreadcrumb: false,
      sticky: false,
      menuVariant: "bottom-bar",
      topHeaderVariant: "hamburger-nav",
      showMenuSeparators: false,
      showSouthTyrolLogo: true,
      logoId: undefined,
      logoBackground: "transparent",
      imageOverlayGradient: "dark",
      whatsAppNumber: undefined,
      logoSize: "medium",
    },
  },
  FooterModule: {
    language: {
      title: "Kontaktieren Sie uns",
      description: undefined,
    },
    global: {
      logoCategories: [["047c323d-c032-4a27-ada5-a67d6aebb507"], []],
      layoutVariant: "big",
      logoSize: "big",
      showTrustYou: true,
      textAlign: {
        ...textAlign,
        title: "left",
        description: "left",
      },
    },
  },
  ButtonModule: buttonModuleSettings,
  TextModule: {
    language: {
      title: "Lorem ipsum dolor sit amet",
      subtitle:
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam",
      description: undefined,
    },
    global: {
      mediaAspectRatio: 1.7778,
      textAlign,
      mediaAlign: "left",
      width: "container-width",
      columns: 1,
      boxAlign: "center",
    },
  },
  OverlayModule: {
    language: {
      title: "",
      subtitle: "",
    },
    global: {
      fontSize: "medium",
      horizontalAlign: "left",
      textStyle: "plain",
      verticalAlign: "bottom",
    },
  },
  ImagesModule: {
    language: {
      title: "",
      subtitle: "",
    },
    global: {
      autoPlay: false,
      autoPlayDelay: 3,
      columnsCount: 1,
      width: 6,
      gap: "none",
      mediaAspectRatio: 1.7778,
      imagesType: "separator",
      sliderEffect: "slide",
      textAlign: titleAndSubtitleTextAlign,
    },
  },
  ImageModule: {
    global: {
      crop: defaultImageCropParams,
      importantPoint: defaultImportantImagePoint,
      pictureId: "",
    },
    language: {},
  },
  ImageGalleryModule: {
    language: {
      title: "",
      subtitle: "",
    },
    global: {
      mediaAspectRatio: 1.7778,
      textAlign: titleAndSubtitleTextAlign,
    },
  },
  RoomsModule: {
    ...offeringModuleSettings,
    global: {
      ...offeringModuleSettings.global,
      layout: "layout_4",
    },
  },
  MapModule: {
    language: {},
    global: {
      zoom: 15,
    },
  },
  BookingModule: {
    language: {
      title: "",
      subtitle: "",
    },
    global: {
      license: "extended",
      defaultOfferList: "rooms",
      layout: "one_pager",
      textAlign: titleAndSubtitleTextAlign,
      fieldSettings: defaultFieldSettings.BookingModule,
    },
  },
  PortalModule: {
    language: {
      title: "",
      subtitle: "",
    },
    global: {
      textAlign: titleAndSubtitleTextAlign,
      id: "",
      showSearch: true,
    },
  },
  NotFoundTextModule: {
    language: {},
    global: {},
  },
  SpecialsModule: offeringModuleSettings,
  PriceModule: {
    language: {
      title: "Preisliste",
      subtitle: "",
      description: undefined,
    },
    global: {
      layout: "layout_1",
      grouping: "board",
      offerIds: [],
      roomIds: [],
      boardIds: [],
      textAlign,
    },
  },
  EnquiryModule: {
    language: {
      title: "Unverbindliche Anfrage",
      subtitle: "",
    },
    global: {
      textAlign: titleAndSubtitleTextAlign,
      fieldSettings: defaultFieldSettings.EnquiryModule,
      layout: "layout_1",
    },
  },
  QuickEnquiryModule: {
    language: {},
    global: {
      badgeStyle: "text",
      layout: "bottom_left",
      fieldSettings: defaultFieldSettings.QuickEnquiryModule,
    },
  },
  HighlightsModule: {
    language: {
      title: "",
      subtitle: "",
    },
    global: {
      textAlign: titleAndSubtitleTextAlign,
      layout: "layout-1",
      displayType: "list",
      mediaAspectRatio: 1.3333,
      maxColumnsCount: 2,
      collapsedLinesCount: undefined,
      transparentSliderArrowBackground: false,
      mediaAlign: "left",
    },
  },
  HighlightModule: {
    language: {
      title: "Lorem ipsum",
      subtitle: "",
      description: undefined,
    },
    global: {
      textAlign: highlightTextAlign,
    },
  },
  ImprintModule: {
    language: { legalAddress: "" },
    global: {
      legalForm: undefined,
      paidUpShareCapital: undefined,
      pecAddress: "",
      reaNumber: "",
      taxCode: "",
    },
  },
  PrivacyModule: {
    language: {
      title: "Datenschutz",
      description: undefined,
    },
    global: {
      textAlign,
    },
  },
  TermsModule: {
    language: {},
    global: {},
  },
  VideoModule: {
    language: {
      title: "",
      subtitle: "",
    },
    global: {
      textAlign: titleAndSubtitleTextAlign,
      videoUrl: "",
      controls: true,
      showInfo: false,
      autoPlay: false,
      startAt: "00:00",
      size: "fullWidth",
      mute: false,
    },
  },
  EasiPayModule: {
    language: {
      title: "",
      subtitle: "",
    },
    global: {
      textAlign: titleAndSubtitleTextAlign,
      authId: "",
    },
  },
  EasiFastCheckInModule: {
    language: {
      title: "",
      subtitle: "",
    },
    global: {
      url: "",
      textAlign: titleAndSubtitleTextAlign,
    },
  },
  HTMLModule: {
    language: {
      title: "Lorem ipsum dolor sit amet",
      subtitle: "",
      html: "",
    },
    global: {
      textAlign: titleAndSubtitleTextAlign,
    },
  },
  MTSToursModule: {
    language: {
      title: "",
      subtitle: "",
    },
    global: {
      textAlign: titleAndSubtitleTextAlign,
      archive: "",
      user: "",
    },
  },
  GastroPoolInsuranceModule: {
    language: {
      title: "",
      subtitle: "",
      key: "",
    },
    global: {
      textAlign: titleAndSubtitleTextAlign,
    },
  },
  SeparatorModule: {
    language: {},
    global: {
      icon: "tree-1",
      width: "container-width",
      height: "medium",
      iconCount: 1,
      iconSize: "medium",
      lineStyle: "straight",
      lineWeight: "light",
    },
  },
  WeatherWebcamModule: {
    language: {},
    global: {
      layoutVariant: "vertical",
    },
  },
  WeatherModule: {
    language: { title: "Lorem ipsum", subtitle: "" },
    global: {
      districtId: undefined,
      iconStyle: "colored",
      textAlign,
    },
  },
  WebcamModule: {
    language: {
      title: "",
      subtitle: "",
      description: undefined,
      imageCaption: "",
    },
    global: {
      url: "",
      textAlign,
      layoutVariant: "image-first",
      size: "big",
    },
  },
  Google360VirtualTourModule: {
    language: {
      title: "Lorem ipsum dolor sit amet",
      subtitle: "",
    },
    global: {
      textAlign,
      url: undefined,
    },
  },
  EmbeddedGoogleMapsModule: {
    language: {
      url: undefined,
    },
    global: {},
  },
  PopUpModule: {
    language: {
      title: "Lorem ipsum dolor sit amet",
      description: undefined,
      subtitle: "",
    },
    global: {
      mediaAspectRatio: 1.7778,
      textAlign,
      imageAlign: "top",
      boxAlign: "center",
      darkBackground: true,
      onlyShowWhenUserInactive: false,
    },
  },
  NewsletterModule: {
    language: {
      title: "Newsletter",
      subtitle: "",
      description: undefined,
    },
    global: {
      textAlign,
      fieldSettings: defaultFieldSettings.NewsletterModule,
      layout: "layout-1",
    },
  },
  PeerTvModule: {
    language: {
      title: "",
      subtitle: "",
      caption: "",
      scriptUrl: "",
      websiteUrl: "",
    },
    global: {
      textAlign,
      size: "big",
    },
  },
  ACSmartVoucherModule: {
    language: {
      title: "",
      subtitle: "",
    },
    global: {
      textAlign,
      cssUrl: "",
      kID: "",
    },
  },
  QuestionsAndAnswersModule: {
    language: {
      title: "Lorem ipsum",
      subtitle: "",
    },
    global: {
      textAlign: titleAndSubtitleTextAlign,
    },
  },
  QuestionAndAnswerModule: {
    language: {
      title: "Lorem ipsum",
      description: undefined,
      plainDescription: undefined,
    },
    global: { textAlign: highlightTextAlign },
  },
  GuestnetWidgetModule: {
    language: {
      title: "",
      subtitle: "",
    },
    global: { textAlign: titleAndSubtitleTextAlign, baseUrl: "" },
  },
  HogastGastropoolPayModule: {
    language: {
      title: "",
      subtitle: "",
    },
    global: {
      textAlign: titleAndSubtitleTextAlign,
      key: "",
    },
  },
  HogastGastropoolSmartPayModule: {
    language: {
      title: "",
      subtitle: "",
    },
    global: {
      textAlign: titleAndSubtitleTextAlign,
      key: "",
      provider: "hogast",
      options: { deposit: true, travelInsurance: true },
    },
  },
  QuoteModule: {
    language: {
      title: "",
      subtitle: "",
      author: "Lorem ipsum",
      quote:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt, quod.",
    },
    global: {
      fontFamily: null,
      textAlign,
      mediaAlign: "left",
      layout: "layout-1",
      fontSize: "medium",
    },
  },
  AccommodationFeaturesModule: {
    global: {
      textAlign: titleAndSubtitleTextAlign,
      collapsedLinesCount: undefined,
      maxColumns: 3,
      pinnedItems: [],
    },
    language: { title: "Lorem ipsum dolor sit amet", subtitle: "" },
  },
  ArriveInSouthTyrolModule: {
    language: {
      title: "",
      subtitle: "",
    },
    global: {
      textAlign: titleAndSubtitleTextAlign,
    },
  },
  GetavoModule: {
    language: {
      title: "",
      subtitle: "",
    },
    global: {
      textAlign: titleAndSubtitleTextAlign,
      code: "",
    },
  },
};

const postModuleStart = (
  state: ModulesById,
  action: PostModuleParams,
): ModulesById => {
  const {
    languageIds,
    moduleType,
    moduleId,
    pageId,
    siteId,
    parentId,
    settings = {},
  } = action;

  const newTranslations = languageIds.reduce<ModuleTranslations>(
    (acc, languageId) => {
      const translation: ModuleTranslation = {
        settings: deepExtend(
          {},
          defaultSettings[moduleType].language,
          settings.language ?? {},
        ),
      };
      return { ...acc, [languageId]: translation };
    },
    {},
  );

  const newModule: Module = {
    colorSchemeId: null,
    id: moduleId,
    pageId,
    siteId,
    type: moduleType,
    parentId,
    translations: newTranslations,
    settings: deepExtend(
      {},
      defaultSettings[moduleType].global,
      settings.global ?? {},
    ),
  };

  return { ...state, [moduleId]: newModule };
};

const getModules = (state: ModulesById, modules: Module[]): ModulesById => {
  const modulesById = modules.reduce<ModulesById>((modules, module) => {
    const { global, language } = defaultSettings[module.type];
    const translations = keys(module.translations).reduce<ModuleTranslations>(
      (acc, languageId) => {
        const translation = module.translations[languageId];
        const settingsbyLanguage = deepExtend(
          {},
          language,
          translation ? translation.settings : {},
        );

        return {
          ...acc,
          [languageId]: {
            settings: settingsbyLanguage,
          },
        };
      },
      {},
    );

    const currentModule: Module = {
      ...module,
      translations,
      settings: { ...global, ...module.settings },
    };

    return {
      ...modules,
      [module.id]: currentModule,
    };
  }, state);

  return modulesById;
};

const getModulesFromPages = (state: ModulesById, pages: Page[]) =>
  getModules(state, getModulesFromAllPages(pages));

const changeModule = (
  state: ModulesById,
  moduleId: string,
  changeset: Partial<Module>,
): ModulesById => {
  const currentModule = state[moduleId];
  if (!currentModule) throw new ModuleNotFoundError(moduleId);
  const module: Module = { ...currentModule, ...changeset };
  return { ...state, [moduleId]: module };
};

const translateModule = (
  state: ModulesById,
  moduleId: string,
  languageId: Language,
  sourceLanguageId: Language,
) => {
  const currentModule = state[moduleId];
  const translation = currentModule?.translations[sourceLanguageId];
  const translationAlreadyExists = Boolean(
    currentModule?.translations[languageId],
  );

  if (!translation || translationAlreadyExists) {
    return state;
  }

  const newTranslation: ModuleTranslation = {
    settings: translateModuleSettings(translation.settings, languageId),
  };

  return changeModule(state, moduleId, {
    translations: {
      ...currentModule.translations,
      [languageId]: newTranslation,
    },
  });
};

const deleteModuleTranslation = (
  state: ModulesById,
  moduleId: string,
  languageId: Language,
  isUntranslated: boolean,
): ModulesById => {
  const currentModule = state[moduleId];
  if (!currentModule) return state;

  // Delete the entire module
  if (isUntranslated) {
    const newState = { ...state };
    delete newState[moduleId];
    return newState;
  }

  // Delete only one translation translation
  const newTranslations = { ...currentModule?.translations };
  delete newTranslations[languageId];

  const updatedModule: Module = {
    ...currentModule,
    translations: newTranslations,
  };

  return { ...state, [moduleId]: updatedModule };
};

const setModuleSettings = (
  state: ModulesById,
  moduleId: string,
  languageId: Language,
  settings: PartialModuleSettings<ModuleSettingsTypes>,
): ModulesById => {
  const currentModule = state[moduleId];
  if (!currentModule) throw new ModuleNotFoundError(moduleId);
  const translation: ModuleTranslation = currentModule?.translations[
    languageId
  ] || {
    settings: {},
  };

  const newModuleTranslation: ModuleTranslation = {
    settings: {
      ...translation.settings,
      ...(settings.language || {}),
    },
  };

  const updatedModule: Module = {
    ...currentModule,
    translations: {
      ...currentModule?.translations,
      [languageId]: newModuleTranslation,
    },
    settings: {
      ...currentModule?.settings,
      ...(settings.global || {}),
    },
  };

  return { ...state, [moduleId]: updatedModule };
};

const deleteModulesColorScheme = (
  state: ModulesById,
  colorSchemeId: string,
): ModulesById => {
  return Object.keys(state).reduce<ModulesById>((accumulator, moduleId) => {
    const currentModule = state[moduleId];
    if (!currentModule) throw new ModuleNotFoundError(moduleId);
    const updatedModule: Module = {
      ...currentModule,
      colorSchemeId:
        currentModule.colorSchemeId === colorSchemeId
          ? null
          : (currentModule.colorSchemeId ?? null),
    };

    return {
      ...accumulator,
      [moduleId]: updatedModule,
    };
  }, initialState);
};

const updateLogoCategory = (
  state: ModulesById,
  moduleId: string,
  logoCategory: LogoCategory,
  callback: (logoIds: string[]) => string[],
) => {
  const module = state[moduleId];
  if (!module || !isFooterModule(module)) return state;

  const { logoCategories } = module.settings;

  return changeModule(state, moduleId, {
    settings: {
      ...module.settings,
      logoCategories: Object.assign([], logoCategories, {
        [logoCategory]: callback([...logoCategories[logoCategory]]),
      }),
    },
  });
};

const moveLogoStart = (
  state: ModulesById,
  moduleId: string,
  logoCategory: LogoCategory,
  dragIndex: number,
  hoverIndex: number,
): ModulesById => {
  return updateLogoCategory(state, moduleId, logoCategory, (logoIds) => {
    return moveArrayElement(logoIds, dragIndex, hoverIndex);
  });
};

const deleteLogoStart = (
  state: ModulesById,
  moduleId: string,
  logoCategory: LogoCategory,
  logoId: string,
): ModulesById => {
  return updateLogoCategory(state, moduleId, logoCategory, (logoIds) => {
    return logoIds.filter((id) => id !== logoId);
  });
};

const upsertLogoStart = (
  state: ModulesById,
  moduleId: string,
  logoCategory: LogoCategory,
  newLogoId: string,
  previousLogoId: string | undefined,
): ModulesById => {
  return updateLogoCategory(state, moduleId, logoCategory, (logoIds) => {
    if (!previousLogoId) {
      return [...logoIds, newLogoId];
    }

    const index = logoIds.indexOf(previousLogoId);

    if (index === -1) {
      return [...logoIds, newLogoId];
    }

    return Object.assign([], logoIds, { [index]: newLogoId });
  });
};

const reducer = (state = initialState, action: AllActions): ModulesById => {
  switch (action.type) {
    case "POST_MODULE_START":
      return postModuleStart(state, action);

    case "CHANGE_MODULE":
      return changeModule(state, action.moduleId, action.changeset);

    case "TRANSLATE_MODULE_START":
      return translateModule(
        state,
        action.moduleId,
        action.languageId,
        action.sourceLanguageId,
      );

    case "DELETE_MODULE_TRANSLATION_START":
      return deleteModuleTranslation(
        state,
        action.moduleId,
        action.languageId,
        action.deleteAllTranslations,
      );

    case "SET_MODULE_SETTINGS":
      return setModuleSettings(
        state,
        action.moduleId,
        action.languageId,
        action.settings,
      );

    case "GET_PAGES_SUCCESS":
      return getModulesFromPages(state, action.pages);

    case "GET_SITE_MODULES_SUCCESS":
    case "GET_MODULES_SUCCESS":
      return getModules(state, action.modules);

    case "DELETE_MODULES_COLOR_SCHEME":
      return deleteModulesColorScheme(state, action.colorSchemeId);

    case "MOVE_LOGO_START":
      return moveLogoStart(
        state,
        action.moduleId,
        action.logoCategory,
        action.dragIndex,
        action.hoverIndex,
      );

    case "DELETE_LOGO_START":
      return deleteLogoStart(
        state,
        action.moduleId,
        action.logoCategory,
        action.logoId,
      );

    case "UPSERT_LOGO_START":
      return upsertLogoStart(
        state,
        action.moduleId,
        action.logoCategory,
        action.newLogoId,
        action.previousLogoId,
      );

    default:
      return state;
  }
};

export default reducer;
