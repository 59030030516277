import ClassNames from "classnames";
import { FunctionComponent, useEffect, useState } from "react";
import { ConnectedProps, MapStateToProps, connect } from "react-redux";
import { injectScript } from "../../actions/LoadStates.js";
import { getActiveSite } from "../../selectors/sites.js";
import {
  BaseModuleProps,
  ColorScheme,
  EasiPayModuleSettings,
  StoreState,
  WindowState,
} from "../../types/index.js";
import { getActiveColorScheme } from "../../utils/utils.js";
import LazyloadWrapper from "../LazyloadWrapper.js";
import ModuleHeadings from "../ModuleHeadings.js";
import ModuleWithHeadings from "../ModuleWithHeadings.js";

interface Props extends BaseModuleProps<EasiPayModuleSettings> {}

interface StateProps {
  scheme: ColorScheme;
}

type ReduxProps = ConnectedProps<typeof connector>;

const EasiPayModule: FunctionComponent<Props & ReduxProps> = ({
  scheme,
  translatedModule: {
    id,
    settings: { authId, textAlign },
    translation: {
      languageId,
      settings: { title, subtitle },
    },
  },
  injectScript,
  isActive,
  isPreview,
  isFirstOnPage,
}) => {
  const [isLazyloaded, setIsLazyloaded] = useState(false);

  useEffect(() => {
    authId &&
      isLazyloaded &&
      injectScript("https://www.easymailing.eu/frontend/easiplugin.php").then(
        () => {
          const windowState = window as unknown as WindowState;
          windowState.loadframe?.();
        },
      );
  }, [isLazyloaded, isPreview, authId]);

  if (!isPreview && !authId) return null;

  return (
    <ModuleWithHeadings
      title={title}
      subtitle={subtitle}
      id={id}
      className="EasiPayModule"
      colors={{
        background: scheme.main.background,
        color: scheme.main.text,
      }}
    >
      <div className="Module__Wrapper">
        <ModuleHeadings
          scheme={scheme}
          isFirstOnPage={isFirstOnPage}
          textAlign={textAlign}
          title={title}
          subtitle={subtitle}
        />
      </div>

      <LazyloadWrapper onLoad={setIsLazyloaded}>
        <div
          className={ClassNames("Module__Wrapper EasiPayModule__Content", {
            "EasiPayModule__Content--empty": !authId,
            "EasiPayModule__Content--no-click": !isActive && isPreview,
          })}
        >
          <div
            key={authId}
            id="easiplugin_container"
            data-module="easipay"
            data-lang={languageId}
            data-key={authId}
            className="EasiPayModule__Container"
          />
        </div>
      </LazyloadWrapper>
    </ModuleWithHeadings>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { colorSchemes, sites },
  { translatedModule },
): StateProps => ({
  scheme: getActiveColorScheme(
    colorSchemes,
    getActiveSite(sites),
    translatedModule,
  ),
});

const mapDispatchToProps = {
  injectScript,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(EasiPayModule);
