import { FunctionComponent } from "react";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import { getActiveSite } from "../../selectors/sites.js";
import {
  BaseModuleProps,
  PortalModuleSettings,
  PortalWidgetSettings,
  StoreState,
} from "../../types/index.js";
import {
  getActiveColorScheme,
  getSupportedLanguage,
} from "../../utils/utils.js";
import ModuleHeadings from "../ModuleHeadings.js";
import ModuleWithHeadings from "../ModuleWithHeadings.js";
import PortalWidget from "../PortalWidget.js";

interface Props extends BaseModuleProps<PortalModuleSettings> {}

interface StateProps {
  settings: PortalWidgetSettings;
}

type ReduxProps = ConnectedProps<typeof connector>;

const PortalModule: FunctionComponent<Props & ReduxProps> = ({
  translatedModule: {
    id,
    settings: { textAlign },
    translation: {
      settings: { title, subtitle },
    },
  },
  settings,
  settings: { colorScheme: scheme },
  isFirstOnPage,
}) => (
  <ModuleWithHeadings
    title={title}
    subtitle={subtitle}
    id={id}
    className="PortalModule"
    colors={{
      background: scheme.main.background,
    }}
  >
    <div className="Module__Wrapper">
      <ModuleHeadings
        scheme={scheme}
        isFirstOnPage={isFirstOnPage}
        textAlign={textAlign}
        title={title}
        subtitle={subtitle}
      />
    </div>
    <PortalWidget settings={settings} />
  </ModuleWithHeadings>
);

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { colorSchemes, sites },
  {
    translatedModule,
    translatedModule: {
      translation: { languageId },
      settings: { id, showSearch },
    },
  },
): StateProps => {
  const site = getActiveSite(sites);

  return {
    settings: {
      id,
      lang: getSupportedLanguage(languageId, ["de", "it", "en"]),
      colorScheme: getActiveColorScheme(colorSchemes, site, translatedModule),
      showSearch,
    },
  };
};

const connector = connect(mapStateToProps);

export default connector(PortalModule);
